import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@shared/services/toast/toast.service';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CalendarsService {
  defaultmax: number = 150;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  /**
   * Function used to generate calendar api call
   * @param calendar object that has stored data which we use in api call
   * @returns false if fails otherwise returns series of numbers
   */
  createCalendar(calendar, status_id) {

    let data = {
      "name": calendar.name,
      "startdate": calendar.startdate,
      "enddate": calendar.enddate,
      "status_id": status_id
    }

    return this.http.post<any>(`${baseUrl}/api/calendars`, data)
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.createCalendarSuccess'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
            this.toastService.sendToast(false, this.translateService.instant('services.createCalendarFail'))
          return of(false)
        })
      )

  }

  updateCalendar(calendar, calendarId) {
    let newdataJSON = {
      "startdate": calendar.startdate,
      "enddate": calendar.enddate,
      "msa_id": calendar.msaId,
      "cleanup_id": calendar.cleanupCalendarId,
      "slot_max_default": calendar.slot_max_default
    }

    let newdata = JSON.stringify(newdataJSON)


    this.http.patch<any>(`${baseUrl}/api/calendars/${calendarId}`, { newdata })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.updateCalendarSuccess'))
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      });

  }

  updateCalendarSlotInformation(slot) {
    let disabledValue;
    if (slot.disabled == false) disabledValue = 0
    else disabledValue = 1


    let startTime = slot.timeStart.split(":")[0] + ":" + slot.timeStart.split(":")[1]
    let endTime = slot.timeEnd.split(":")[0] + ":" + slot.timeEnd.split(":")[1]

    let newdataJSON = {
      "starttime": slot.date + " " + startTime,
      "endtime": slot.date + " " + endTime,
      "disabled": disabledValue,
      "disabled_reason": slot.description,
      "slot_max": slot.slot_max
    }

    let newdata = JSON.stringify(newdataJSON)


    return this.http.patch(`${baseUrl}/api/calendarslots/${slot.id}`, { newdata })
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(() => {
          this.toastService.sendToast(false, this.translateService.instant('services.slotUpdateFailed'))
          return of(false)
        })
      )


  }

  createSlot(slot, calendarId) {
    const disabledValue = slot.disabled ? 1 : 0;


    const data = {
      calendar_id: calendarId,
      starttime: slot.starttime,
      endtime: slot.endtime,
      disabled: disabledValue,
      disabled_reason: slot.description,
      slot_max: slot.slot_max,
    };



    return this.http.post<any>(`${baseUrl}/api/calendarslots`, data)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
        this.toastService.sendToast(false, this.translateService.instant('services.createSlotFailed'))
          return of(false)
        })
      )

  }

  removeSlot(slotId) {
    const url = `${baseUrl}/api/calendarslots/${slotId}`;
    return this.http.delete(url).pipe(
      tap(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.deletedMarker'));
      }),
      catchError(error => {
        this.toastService.sendToast(true, 'Error deleting marker');
        return throwError(error);  // Propagate the error to the caller
      })
    );
  }



  getSlot(slotId): Observable<any> {
    return this.http.get<any>(`${baseUrl}/api/calendarslots/${slotId}`)
      .pipe(
        map((data: any) => {
          // Do something with data if needed
          return data;
        }),
        catchError(error => {
          this.toastService.sendToast(false, this.translateService.instant('services.getSlotFailed'))
          return of(false)
        })
      );
  }

  getCalendars() {
    return this.http.get(`${baseUrl}/api/calendars`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false);
        })
      )
  }

  getCalendarById(id: number, status_id: any): Observable<any> {
    const url = `${baseUrl}/api/calendars/${id}`;
    const params = new HttpParams().set('status_id', status_id);

    return this.http.get(url, { params: params })
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(error => {
          //console.log(error)
          return of(false);
        })
      )
  }

  getCalendarsByWeekAndProjects(projects: number[], startDate: string | null, endDate: string | null): Observable<any> {
    let requestData: any = {
      projectIds: projects,
    };

    if (startDate !== null && endDate !== null) {
      requestData = {
        ...requestData,
        startDate: startDate,
        endDate: endDate,
      };
    }

    return this.http.post(`${baseUrl}/api/calendarWithWeekAndProject`, requestData)
      .pipe(
        map((response: any) => {
          if (response.status === 'success') {
            return response;
          } else {
            throw new Error(response.message);
          }
        }),
        catchError(error => {
          console.log(error);
          return of([]);
        })
      );
  }


  linkToWorkerteam(workerteamId, calendarId) {
    let data = {
      "calendar_id": calendarId,
      "workerteam_id": workerteamId,
    }

    const url = `${baseUrl}/api/calendars/${calendarId}/${workerteamId}`;

    return this.http.post<any>(url , data)
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.linkCalendarToWorkerteamSuccess'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  unLinkToWorkerteam(workerteamId, calendarId) {
    return this.http.delete<any>(`${baseUrl}/api/calendars/${calendarId}/${workerteamId}`)
      .pipe(
        map((data: any) => {
          this.toastService.sendToast(true, this.translateService.instant('services.calendarRemovedFromWorkerteam'))
          return data['message'];
        }),
        catchError(error => {
          //alert(error.error)
          return of(false)
        })
      )
  }

  updateCalendarSlots(key, workorderId, message, selectedStatus): any {

    const url = `${baseUrl}/api/calendarslots/${key}/${workorderId}`;
    if (message == 'delete') {
      let body = null
      return this.http.delete(url).subscribe(
        response => {
          this.toastService.sendToast(true, this.translateService.instant('services.deletedMarker'))
        },
        error => {
          this.toastService.sendToast(true, 'Error deleting marker')
        }
      );
    }
    else if (message == 'add') {
      let body = {
        "status_id": selectedStatus
      }
      return this.http.post(`${baseUrl}/api/calendarslots/${key}/${workorderId}`, body)
        .pipe(
          map((data: any) => {
            this.toastService.sendToast(true, this.translateService.instant('services.addedMarker'))
            return data;
          }),
          catchError(error => {
            return of(false)
          })
        )
    } else if (message == 'update') {

    }

  }

  updateSlotMaxFill(id, maxFill): Observable<any> {
    if (maxFill > this.defaultmax) {
      maxFill = this.defaultmax
    }
    let newdataJSON = {
      "slot_max": maxFill
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/calendarslots/${id}`, {
      newdata
    })
  }

  lockSlot(id, locked): Observable<any> {

    let newdataJSON = {
      "locked": locked
    }

    let newdata = JSON.stringify(newdataJSON)

    return this.http.patch(`${baseUrl}/api/calendarslots/${id}`, {
      newdata
    })
  }

  getProjectCalendars(projectId, startdate, enddate) {

    return this.http.get(`${baseUrl}/api/projectcalendars/${projectId}`,
      {
        params: {
          'startdate': startdate,
          'enddate': enddate
        }
      })

  }

  dateToYMD(date) {

    let yourDate = new Date(date)

    let month = yourDate.getMonth() + 1
    let newMonth = month.toString()

    if (month < 10) {
      newMonth = '0' + month
    }

    let day = yourDate.getDate()
    let newDay = day.toString()

    if (day < 10) {
      newDay = '0' + day
    }

    let dateString = yourDate.getFullYear() + '-' + newMonth + '-' + newDay

    return dateString.toString()
  }

  updateCalendarForwarded(calendarId, value) {
    let newdataJSON = {
      "forwarded": value
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch<any>(`${baseUrl}/api/calendars/${calendarId}`, { newdata })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.updateForwarded'))
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      });

  }

  getCalendarIdsWithGroups(groups, state): Observable<any> {
    const groupIds = groups.map(group => group.id);
    const groupIdsString = groupIds.join(',');

    // Updated endpoint
    const url = `${baseUrl}/api/calendarswithworkerteams?groups=${groupIdsString}&state=${state}`;

    return this.http.get<any>(url).pipe(
      map((data: any) => data),
      catchError(error => {
        console.error(error);
        return of(false);
      })
    );

    
  }

  updateCalendarSlotsMass(slotId, workorderIds, message, status) {
    if (message === 'add') {
      let body = {
        status_id: status.id,
        workorder_ids: workorderIds.toString()
      }
      return this.http.post(`${baseUrl}/api/calendarslotsmultiple/${slotId}`, body)
        .pipe(
          map((data: any) => {
            this.toastService.sendToast(true, this.translateService.instant('services.addedMarker'))
            return data;
          }),
          catchError(error => {
            return of(false)
          })
      )
    } else return of(null)
  }








}
