import { Component, DoCheck, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'app-overview-side-nav',
  templateUrl: './overview-side-nav.component.html',
  styleUrls: ['./overview-side-nav.component.scss']
})
export class OverviewSideNavComponent implements OnInit, OnChanges  {

  userType: number = parseInt(<string>localStorage.getItem('ROLE'));

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService) { }

  // Submenu handling when the user is reloading the site / visiting the site from a link
  ngOnInit(): void {
    this.sideBarHandler()
  }

  ngOnChanges() {
    //this.sideBarHandler()
  }

  sideBarHandler() {
    const output = document.getElementById('mapSubmenu');
    const output2 = document.getElementById('dashSubmenu');
    const output3 = document.getElementById('humanResourcesSubmenu');


    // Map submenu

    if (output) {
    }

    // Project submenu
    if (output2) {
      if (this.activatedRoute.children[0].snapshot.url[0].path == "dashboard") {
        output2.style.display = "block"
      }
    }

    // Human resources submenu
    if (output3) {
    }

  }
}
