<div class="page-container">
    <div class="page-heading">
        <h3 class="bold-text black-text"><img src="assets/icons/person_black_24dp.svg"><span class="ms-2">{{'sideNavigation.titleWorkers' | translate}}</span></h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div class="margin-left">
                    <button class="btn btn-secondary border-zero padding-fix" (click)="toWorkersPage()">{{'basic.back' | translate}}</button>
                    <form ngNativeValidate (ngSubmit)="saveEdit(1)">
                    <div class="row margin-top">

                        <div class="col-5">
                            <div class="large bold-text row">
                                <div>
                                    <span id="worker-name">{{name}}</span>
                                    <button class="btn btn-secondary float-end me-3" (click)="toggleEdit(1)" *ngIf="!editMode1"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                </div>

                            </div>
                            <div class="row small-text mt-5" >
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.phoneNumber' | translate}}</div>
                                    <div *ngIf="editMode1 == false">
                                        <div class="bold-text">{{phone}}</div>
                                        <div class="bold-text" *ngIf="phone == null">{{'planner.workerDetail.noPhoneNumber' | translate}}</div>
                                    </div>
                                    <div class="form-group" *ngIf="editMode1 == true">
                                        <input type="text" class="form-control max-content" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.email' | translate}}</div>
                                    <div *ngIf="editMode1 == false">
                                        <div class="bold-text">{{email}}</div>
                                        <div class="bold-text" *ngIf="email == null">{{'basic.noEmail' | translate}}</div>
                                    </div>
                                    <div class="form-group" *ngIf="editMode1 == true">
                                        <input type="email" id="emailId" class="form-control max-content" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" required>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.interfaceLanguage' | translate}}</div>
                                    <div *ngIf="editMode1 === false" class="bold-text">
                                        <div>{{workerLanguage.displayName}}</div>
                                        <div *ngIf="!workerLanguage.displayName">{{'basic.noLanguage' | translate}}</div>
                                    </div>
                                    <div [hidden]="!editMode1">
                                        <select class="form-select" (change)="languageChange($event.target.value)">
                                            <option *ngIf="!workerLanguage.displayName" disabled [selected]="!workerLanguage.displayName">{{'basic.noLanguage' | translate}}</option>
                                            <option *ngFor="let language of interfaceLanguages" [selected]="workerLanguage.databaseLanguage === language.databaseLanguage" [value]="language.databaseLanguage">{{language.displayName}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div class="row" *ngIf="editMode1">
                                <div class="col-12 mb-2">
                                    <button type="submit" class="btn btn-primary">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(1)">{{'basic.cancel' | translate}}</button>
                                </div>
                            </div>
                            <div class="border-bottom-96 mt-5"></div>
                        </div>
                        <div class="col-7 small-text border-start border-gray">
                            <div class="ms-4">
                                <div class="gray-text">
                                    <span>{{'planner.workerDetail.workersEfficiency' | translate}}</span>
                                    <button class="btn btn-secondary float-end" type="button" (click)="toggleEdit(2)" *ngIf="!editMode2"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                </div>
                                <div class="gray-text"></div>
                                <div *ngIf="editMode2 === false">
                                    <circle-progress
                                        [percent]="efficiency"
                                        [radius]="18"
                                        [outerStrokeWidth]="4"
                                        [innerStrokeWidth]="4"
                                        [space]="-4"
                                        [outerStrokeColor]="colorOuter"
                                        [innerStrokeColor]="colorInner"
                                        [animation]="true"
                                        [animationDuration]="300"
                                        [titleFontSize]="'13'"
                                        [title]="efficiencyValue"
                                        [showUnits]=false
                                        [titleColor]="colorOuter"
                                        [titleFontWeight]="'600'"
                                    ></circle-progress>
                                </div>
                                <div class="form-group" *ngIf="editMode2 === true">
                                    <input type="number" min="0.5" max="2.0" step="0.01" class="form-control max-content" [(ngModel)]="efficiencyValue" [ngModelOptions]="{standalone: true}">
                                </div>
                                <div class="mt-5">
                                    <div class="gray-text">{{'planner.workerDetail.belongsToTeams' | translate}}</div>
                                    <div *ngFor="let team of teams; let i = index" class="item bold-text">
                                        <div>
                                            <span>{{team.name}}</span>
                                            <span *ngIf="teams.length - 1 > i">,</span>
                                        </div>
                                    </div>
                                    <div *ngIf="teams == null" class="bold-text">{{'basic.noTeams' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                    <div class="row">
                        <div class="col-5 mt-4">
                            <div>
                                <div class="margin-left-fix">
                                    <img [src]="personAccount" class="image-position"><span class="ms-2 fs-6">{{'basic.account' | translate}}</span>

                                    <button class="btn btn-secondary float-end me-3 font-size-small" (click)="toggleEdit(3)" *ngIf="!editMode3 && !createUser && !accountDisabled"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                    <button class="float-end btn btn-secondary font-size-small me-1" (click)="disableAccount()" *ngIf="!createUser && !accountDisabled">
                                        <img class="disable-icon" src="assets/icons/person_off_gray_48.svg">
                                        <span class="ms-1">{{'basic.disableAccount' | translate}}</span>
                                    </button>
                                    <button class="float-end btn btn-primary font-size-small me-1" (click)="activateAccount()" *ngIf="!createUserActive && accountDisabled">
                                        <img class="disable-icon" src="assets/icons/person_white_24dp.svg">
                                        <span class="ms-1">{{'basic.activateAccount' | translate}}</span>
                                    </button>
                                    <div class="gray-text font-size-small" *ngIf="accountDisabled && !createUserActive">({{'basic.disabled' | translate}})</div>
                                </div>

                            </div>
                            <div *ngIf="createUser">
                                <div class="gray-text mt-3 small-text">{{'basic.noAccount' | translate}}</div>
                                <button class="btn btn-primary mt-3" (click)="createAccount()"><span class=" position-plus">+</span><span class="small-text ms-1">{{'basic.createAccount' | translate}}</span></button>
                            </div>
                            <div *ngIf="!createUser">
                                <div *ngIf="showUsername" class="gray-text small-text mt-3 ">
                                    <span>{{'basic.username' | translate}}: </span>
                                    <span class="black-text bold-text">{{username}}</span>
                                    <button *ngIf="editMode3" class="btn btn-sm btn-secondary ms-1" (click)="changeUsernameToggle()">{{'basic.change' | translate}}</button>
                                </div>
                                <form ngNativeValidate (ngSubmit)="saveEdit(3)">
                                <div *ngIf="!showUsername && editMode3">
                                    <div class="bold-text mt-2">
                                        <span *ngIf="!createUserActive">{{'basic.changeUsername' | translate}}</span>
                                        <span *ngIf="createUserActive">{{'basic.createAccount' | translate}}</span>
                                    </div>
                                    <div *ngIf="!createUserActive">
                                        <div class="small-text gray-text mt-2">{{'basic.oldUsername' | translate}}</div>
                                        <div class="black-text bold-text small-text">{{username}}</div>
                                    </div>
                                    <div class="small-text gray-text mt-2">{{'basic.newUsername' | translate}}</div>
                                    <input type="text" class="form-control max-content" [required]="!showUsername" [(ngModel)]="newUsername" [ngModelOptions]="{standalone: true}">
                                </div>
                                <div *ngIf="showPassword" class="gray-text small-text mt-2">
                                    <span>Password: </span>
                                    <span class="black-text bold-text ms-2">********</span>
                                    <button *ngIf="editMode3" class="btn btn-sm btn-secondary ms-1" (click)="changePasswordToggle()">Change</button></div>
                                <div *ngIf="!showPassword && editMode3">
                                    <div class="small-text gray-text mt-2">
                                        <span>{{'basic.password' | translate}}</span>
                                        <a class="ms-2 small-text generate-link" (click)="generatePassword()">{{'basic.generatePassword' | translate}}</a>
                                    </div>
                                    <div class="password d-flex">
                                        <input id="login-password" type="password" class="form-control password-length max-content" [required]="!showPassword" [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}">
                                        <button (click)="hideShowPassword()" class="border border-gray" type="button"><img *ngIf="!showGeneratedPassword" src="assets/icons/visibility_gray_24dp.svg"> <img *ngIf="showGeneratedPassword" src="assets/icons/visibility_off_gray_24dp.svg"></button>
                                    </div>
                                </div>
                                <div class="mb-2 mt-3" *ngIf="editMode3 == true">
                                    <button type="submit" class="btn btn-primary">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(3)">{{'basic.cancel' | translate}}</button>
                                </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-7 border-start border-gray  small-text">
                            <div class="ms-4">
                                <div *ngIf="editMode2 == true" class="margin-top-1">
                                    <div class=" gray-text">{{'basic.selectSkills' | translate}}</div>
                                    <div class="border border-gray border-2 scrollbar" [style.height]="skillHeight" >
                                        <div *ngFor="let state of allStates; let i = index">
                                            <div class="border-top-alignment"></div>
                                            <label class="container container-padding-left-22 my-2">
                                                <input type="checkbox" [(ngModel)]="allStates[i].checked" [ngModelOptions]="{standalone: true}" (click)="skillClicked(i)">
                                                <span class="checkmark position-checkbox-skill"></span>
                                                <span class="ms-3 skill-text ">{{state.definition}}</span>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div class="mt-2">
                                    <div class="gray-text">{{'basic.skills' | translate}}</div>
                                    <div>
                                        <div *ngFor="let skill of qualifications" class="inline-block">
                                            <div class="skill px-2 rounded-pill">{{skill.definition}}</div>
                                        </div>
                                    </div>
                                    <div *ngIf="!qualifications || qualifications.length == 0" class="bold-text">{{'workers.noSkills' | translate}}</div>
                                </div>
                                <div class="mb-2 mt-3" *ngIf="editMode2 == true">
                                    <button type="button" class="btn btn-primary" (click)="saveEdit(2)">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(2)">{{'basic.cancel' | translate}}</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
