<div id="planner">
    
    <app-header></app-header>
    <app-side-nav></app-side-nav>
    <div id="main">
        <div class="toast-container position-fixed p-3">

            <!-- Then put toasts within -->
            <app-toast></app-toast>
        
        </div> 
        <router-outlet></router-outlet>
    </div>
</div>
