<div class="page-container">
    <div class="page-content p-3">
        <load-spinner *ngIf="spinner"></load-spinner>
        <div *ngIf="!spinner" [ngSwitch]="true">
          <div *ngSwitchCase="this.projectType === 1">
              <button type="button" class="btn btn-sm btn-secondary" (click)="navigateToDashboard()">{{'planner.reportWorkorderDetail.backToDashboard' | translate}}</button>
              <div class="d-flex">
                  <h5 class="col-6">{{'planner.reportWorkorderDetail.report' | translate}}</h5>
                  <div class="col-6 d-flex">
                      <div class="col-4">
                          <div class="fw-bold">{{'calendar.calendar' | translate}}</div>
                          <div>{{calendarName}}</div>
                      </div>
                      <div class="col-4">
                          <div class="fw-bold">{{'basic.timeConfirmed' | translate}}</div>
                          <div *ngIf="currentSlot">{{currentSlot.starttime | date: 'dd.MM.YYYY HH:mm'}} - {{currentSlot.endtime | date: 'dd.MM.YYYY HH:mm'}} </div>
                          <div *ngIf="!currentSlot">{{'planner.reportWorkorderDetail.noSlot' | translate}}</div>
                      </div>
                  </div>
              </div>
              <div class="d-flex mt-3">
                  <div class="col-6 d-flex" *ngIf="interrupted">
                      <div class="col-6">
                          <div class="fw-bold">{{'planner.reportWorkorderDetail.interruptPicture' | translate}}</div>
                          <img *ngIf="interruptImage != ''"  [src]="'data:image/bmp;base64,' + interruptImage.image_blob" class="image-size">
                      </div>
                  </div>
                  <div class="col-6" *ngIf="!interrupted">
                      <div class="fw-bold">{{'planner.reportWorkorderDetail.workorderPictures' | translate}}</div>
                      <div *ngFor="let image of workorderImages">
                          <div>{{'planner.reportWorkorderDetail.imageNumber' | translate}}: {{image.image_place}}</div>
                          <img [src]="'data:image/bmp;base64,' + image.image_blob" class="image-size">

                      </div>
                  </div>
              </div>
              <div class="d-flex mt-3">
                  <div class="col-6">
                      <div class="fw-bold">{{'basic.additionalInformation' | translate}}</div>
                      <div [hidden]="!workorder.notes">{{workorder.notes}}</div>
                      <div [hidden]="workorder.notes" class="red-text">{{'planner.reportWorkorderDetail.noAdditionalInformation' | translate}}</div>
                  </div>
              </div>
              <h5>{{'planner.reportWorkorderDetail.log' | translate}}</h5>
              <div *ngFor="let log of workorderLogs">
                  <div class="d-flex">
                      <div class="col-3">{{log.created | date: 'dd.MM.yyyy HH:mm'}}</div>
                      <div class="col-3 fw-bold">{{log.description}}</div>
                      <div class="col-3">{{'planner.reportWorkorderDetail.by' | translate}} {{log.user_name}}</div>
                      <div class="col-3">{{'planner.reportWorkorderDetail.userID' | translate}} {{log.user_id}}</div>
                  </div>
              </div>
          </div>



          <div *ngSwitchCase="this.projectType === 2">
            <button type="button" class="btn btn-sm btn-secondary" (click)="navigateToDashboard()">{{'planner.reportWorkorderDetail.backToDashboard' | translate}}</button>
            <h4 class="mt-2">{{'basic.workorder' | translate}} #{{workorder.id}}</h4>
            <div class="d-flex">
              <h5 class="col-6">{{'planner.reportWorkorderDetail.report' | translate}}</h5>
              <div class="col-6 d-flex">
                <div class="col-4">
                  <div class="fw-bold">{{'calendar.calendar' | translate}}</div>
                  <div>{{calendarName}}</div>
                </div>
                <div class="col-4">
                  <div class="fw-bold">{{'basic.timeConfirmed' | translate}}</div>
                  <div *ngIf="currentSlot">{{currentSlot.starttime | date: 'dd.MM.YYYY HH:mm'}} - {{currentSlot.endtime | date: 'dd.MM.YYYY HH:mm'}} </div>
                  <div *ngIf="!currentSlot">{{'planner.reportWorkorderDetail.noSlot' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-3">
              <div class="col-6 d-flex" *ngIf="this.workorderStatus.state === 4">
                <div class="col-6">
                  <div class="fw-bold test1">{{'planner.reportWorkorderDetail.isDone' | translate}}</div>
                  <div class="red-text">{{'basic.no' | translate}}</div>
                  <div class="fw-bold">{{'basic.reasonForInterruption' | translate}}</div>
                </div>
                <div class="col-6">
                  <div class="fw-bold">{{'planner.reportWorkorderDetail.interruptPicture' | translate}}</div>
                  <img *ngIf="interruptImage != ''"  [src]="'data:image/bmp;base64,' + interruptImage.image_blob" class="image-size">
                </div>
              </div>
              <div class="col-6" *ngIf="this.workorderStatus.state === 3">
                <div class="col-6">
                  <div class="fw-bold test2">{{'planner.reportWorkorderDetail.isDone' | translate}}</div>
                  <div>{{'basic.yes' | translate}}</div>
                </div>
              </div>
              <div class="col-6 d-flex">
                <div class="col-8 row" >
                  <div class="col-6" >
                  </div>
                </div>
              </div>
            </div>
            <h5 class="mt-3">{{'planner.reportWorkorderDetail.log' | translate}}</h5>
            <div *ngFor="let log of workorderLogs">
              <div class="d-flex">
                <div class="col-3">{{log.created | date: 'dd.MM.yyyy HH:mm'}}</div>
                <div class="col-3 fw-bold">{{log.description}}</div>
                <div class="col-3">{{'planner.reportWorkorderDetail.by' | translate}} {{log.user_name}}</div>
                <div class="col-3">{{'planner.reportWorkorderDetail.userID' | translate}} {{log.user_id}}</div>
              </div>
            </div>
          </div>



          <div *ngSwitchDefault>
<!--            <div class="d-flex">{{'planner.reportWorkorderDetail.noProjectType' | translate}}</div>-->
            <load-spinner *ngIf="spinner"></load-spinner>
            <div class="d-flex">{{'basic.loading' | translate}}</div>
          </div>

        </div>
    </div>
</div>
