import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '@app/app-routing.module';
import { PlannerComponent } from '@layout/planner/planner.component';
import { SharedModule } from '@shared/shared.module';
import { ProjectDashboardComponent } from './components/project-dashboard/project-dashboard.component';
import { ProjectScheduleComponent } from './components/project-schedule/project-schedule.component';
import { MapNavComponent } from './components/map-nav/map-nav.component';
import { NewProjectComponent } from './components/new-project/new-project.component';
import { ProjectPageComponent } from './components/project-page/project-page.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { PlannerManagementComponent } from './components/planner-management/planner-management.component';
import { CallServiceManagementComponent } from './components/callService-management/callService-management.component';
import { CustomerAdminManagementComponent } from './components/customerAdmin-management/customerAdmin-management.component';
import { HumanResourcesComponent } from './components/human-resources/human-resources.component';
import { WorkersComponent } from './components/workers/workers.component';
import { SortDirective } from './sort/directive/sort.directive';
import { SearchPipe } from './pipes/search.pipe';
import { WorkerDetailComponent } from './components/worker-detail/worker-detail.component';
import { PlannerDetailComponent } from './components/planner-detail/planner-detail.component';
import { CallServiceDetailComponent } from './components/callService-detail/callService-detail.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WeeklyPlanningComponent } from './components/weekly-planning/weekly-planning.component';
import { WeeklyPlanningDetailComponent } from './components/weekly-planning-detail/weekly-planning-detail.component';
import { WeeklyPlanningMapComponent } from './components/weekly-planning-map/weekly-planning-map.component';
import { SafeHtml } from './pipes/safe_html.pipe';
import { WeeklyPlanningTeamsComponent } from './components/weekly-planning-teams/weekly-planning-teams.component';
import { TeamsComponent } from './components/teams/teams.component';
import { TeamsDetailComponent } from './components/teams-detail/teams-detail.component';
import { ReportWorkorderDetailComponent } from './components/report-workorder-detail/report-workorder-detail.component';
import { FilterStatesByTypePipe } from './pipes/filter-states-by-type.pipe';

/**
 * This is the core module for planner. This binds all the logic we have for a planner type user.
 * Declares all components that are available for planner, then imports modules that have a more global job.
 */

@NgModule({
  declarations: [
    PlannerComponent,
    SideNavComponent,
    MapNavComponent,
    ProjectPageComponent,
    NewProjectComponent,
    UserManagementComponent,
    PlannerManagementComponent,
    CallServiceManagementComponent,
    CustomerAdminManagementComponent,
    HumanResourcesComponent,
    WorkersComponent,
    SortDirective,
    SearchPipe,
    WorkerDetailComponent,
    PlannerDetailComponent,
    CallServiceDetailComponent,
    WeeklyPlanningComponent,
    WeeklyPlanningDetailComponent,
    WeeklyPlanningMapComponent,
    SafeHtml,
    WeeklyPlanningTeamsComponent,
    ProjectDashboardComponent,
    ProjectScheduleComponent,
    TeamsComponent,
    TeamsDetailComponent,
    ReportWorkorderDetailComponent,
    FilterStatesByTypePipe
  ],
  exports: [
    SideNavComponent,
    FilterStatesByTypePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    AppRoutingModule,
    SharedModule,

    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 20,
      outerStrokeWidth: 8,
      innerStrokeWidth: 2,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      showSubtitle: false
    }),
    NgbModule
  ]
})
export class PlannerModule { }
