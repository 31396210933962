import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { PlannerProjectService } from '@modules/planner/services/planner-project/planner-project.service';
import { TranslateService } from '@ngx-translate/core';
import { Log } from '@shared/models/log';
import { Slot } from '@shared/models/slot';
import { WorkOrder } from '@shared/models/work-order';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-workorder-detail',
  templateUrl: './report-workorder-detail.component.html',
  styleUrls: ['./report-workorder-detail.component.scss'],
  providers:[HttpClient]
})
export class ReportWorkorderDetailComponent implements OnInit, OnDestroy {


  // Global
  componentDestroyed$: Subject<boolean> = new Subject()
  spinner: boolean = true

  // Project
  projectType: any | undefined

  // Status
  workorderStatus: any = {}

  // Workorder
  workorder: WorkOrder = {} as WorkOrder
  workorderImages: Array<any> = Array()
  workorderLogs: Array<Log> = Array()

  // Calendar
  currentSlot: Slot = {} as Slot
  calendarName: string = ""

  // Interrupted
  interrupted: boolean = false
  interruptImage: any = ""


  constructor(
    private plannerProjectService: PlannerProjectService,
    private router: Router,
    private workorderService: WorkOrderService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private http: HttpClient
    ) { }

    /**
     * Get workorder ID from URL and get information shown on page with it.
     * @author Jesse Lindholm
     */
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      // Check if id is number in URL
      if (this.isNumber(params.id)) {
        this.getCalendarByWorkorder(params['id'])
        this.getWorkorderImages(params['id'])
        this.getLogs(params['id'])
        this.getWorkorder(params['id'])
      } else Swal.fire(this.translateService.instant('planner.reportWorkorderDetail.noMeterIdFound'))
    })
  }

  /**
   * Destroy components subscribe listeners, so they don't take up memory.
   * @author Jesse Lindholm
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get workorder logs, update description to match given message.
   * @author Jesse Lindholm
   */
  getLogs(workorderId) {
    this.workorderService.getWorkorderLogs(workorderId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          if (element.description == 'msg_workorder_modified') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.modified')
          else if (element.description == 'msg_workorder_started_working') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.startedWorking')
          else if (element.description == 'msg_workorder_not_started') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.notStarted')
          else if (element.description == 'msg_workorder_moved_to_calendar') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.movedToCalendar')
          else if (element.description == 'msg_workorder_marked_done') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.done')
          else if (element.description == 'msg_workorder_marked_done_with_additional_work') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.doneWithAdditional')
          else if (element.description == 'msg_workorder_interrupted') element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.interrupted')
          else element.description = this.translateService.instant('planner.reportWorkorderDetail.logMessages.unknown')
        }
        this.workorderLogs = data
      }
    )
  }

  // Number check, return false if not number and true if number
  isNumber(string) {
    let isNotNumber = isNaN(+string)
    if (isNotNumber == true) return false
    else return true
  }

  /**
   * Navigate to dashboard
   * @author Jesse Lindholm
   */
  navigateToDashboard() {
    this.router.navigate(['planner/dashboard'])
  }

  /**
   * Get project info
   * @param projectId projects ID
   */
  getProject(projectId) {
    this.plannerProjectService.getProjectById(projectId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.projectType = data.project_type
      })
  }

  /**
   * Get status state
   * @param workorderId status ID
   */
  getStatus(workorderId) {
    this.plannerProjectService.getStatusByWorkorderId(workorderId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.workorderStatus = data
      })
  }

  /**
   * Basic get workorder api call to get information about workorder. Unique handling for
   * access type and status.
   * Status sets interrupted if 6
   * @param id workorders ID
   * @author Jesse Lindholm
   */
  getWorkorder(id) {
    this.workorderService.getWorkOrder(id)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        this.workorder = data
        this.getProject(this.workorder.project_id)
        this.getStatus(this.workorder.id)
        if (data.status_id == 6) this.interrupted = true
        else this.interrupted = false
        this.spinner = false
        
      }
    )
  }

  /**
   * Get calendar and slot data from database with workorder id
   * @param workorderId workorders id
   * @author Jesse Lindholm
   */
  getCalendarByWorkorder(workorderId) {
    return 0;
  }

  /**
   * Get workorders images from database. Different handling for interrupt image.
   * @param workorderId workorders id
   * @author Jesse Lindholm
   */
  getWorkorderImages(workorderId) {
    this.workorderService.getWorkorderImages(workorderId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].image_place != 9) this.workorderImages.push(data[i])
          else this.interruptImage = data[i]


        }
      }
    )
  }

}
