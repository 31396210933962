 <!-- Progress dashboard starts -->
<div class="page-container">
   <div class="page-heading">
      <h3>
         <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
         <span class="page-title">{{'sideNavigation.titleSchedule' | translate}}</span>
      </h3>
   </div>
   <div class="page-content">

      <div class="card shadow-sm" >
         <div class="m-3 row">
            <div class="col-4">
               <h4>{{'sideNavigation.titleSchedule' | translate}}</h4>
<!--               <div class="d-flex">-->
<!--                  <div class="form-switch">-->
<!--                     <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="showCalendarsWithSlots">-->
<!--                  </div>-->
<!--                  <p class="gray-text">{{'planner.projectDashboard.showCalendars' | translate}}</p>-->
<!--               </div>-->
            </div>
         </div>

          <div class="m-3" *ngIf="!pageLoading">
              <div class="m-3" *ngIf="!hasEstimates">
                  <h3>{{'basic.noEstimates' | translate}}</h3>
                  <span class="red-text">{{'basic.checkEstimatesFirst' | translate}}</span>
              </div>
              <div class="" *ngIf="hasEstimates">
                  <div class="row" *ngFor="let state of filterFirstAndLastStates(schedule.states); let i = index">
                      <div class="col-2">
                          <strong>{{'basic.state' | translate}} {{i + 1}}:</strong>
                          <br>
                          {{state.definition}}
                          <br>
                          <small>{{'schedule.estimatePerWorkorder' | translate}}: {{schedule.estimates[state.id]}}</small>
                      </div>
                      <div class="col-2">
                          <h6>{{'basic.startDate' | translate}}</h6>
                          <div>
                              <input type="date" class="form-control fit-content" [style]="state.inputStyle" (change)="dateChangedManual($event, state)" [(ngModel)]="state.startdate" [ngModelOptions]="{standalone: true}" id="editDateStart-{{i}}">
                          </div>
                      </div>
                      <div class="col-1">
                          <h6 *ngIf="state.workorderlength > 0">{{'basic.groups' | translate}}</h6>
                          <input *ngIf="state.workorderlength > 0" type="number" min="1"  step="1" class="form-control max-content" [(ngModel)]="state.groups" [ngModelOptions]="{standalone: true}">
                      </div>
<!--                      <div class="col-2">-->
<!--                          <h6 *ngIf="state.workorderlength > 0">{{'schedule.workersInGroup' | translate}}</h6>-->
<!--                          <input *ngIf="state.workorderlength > 0" type="number" min="1"  step="1" class="form-control max-content" [(ngModel)]="state.workers" [ngModelOptions]="{standalone: true}">-->
<!--                      </div>-->
                      <div class="col-1">
                          ->
                      </div>
                      <div class="col-2">
                          <h6>{{'schedule.deadline' | translate}}</h6>
                          <div>
                              <input type="date" disabled class="form-control fit-content" [(ngModel)]="state.deadline" [ngModelOptions]="{standalone: true}" id="editDeadline-{{i}}">
                          </div>
                        </div>
                      <hr>
                  </div>
                  <div>
                      <button type="submit" class="btn btn-primary" (click)="calculate()">{{'schedule.calculate' | translate}}</button>
<!--                      <button type="submit" class="btn btn-secondary" (click)="save()">{{'schedule.saveCalculations' | translate}}</button>-->
                  </div>
              </div>
          </div>
      </div>
      <div class="justify-content-center w-100 d-flex" *ngIf="pageLoading">
         <load-spinner *ngIf="pageLoading"></load-spinner>
      </div>
   </div>
</div>
<!-- Project dashboard ends -->
