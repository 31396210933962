import { Component, DoCheck, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnChanges  {

  userType: number = parseInt(<string>localStorage.getItem('ROLE'));

  constructor(private activatedRoute: ActivatedRoute,
    private userService: UserService) { }

  // Submenu handling when the user is reloading the site / visiting the site from a link
  ngOnInit(): void {
    this.sideBarHandler()
  }

  ngOnChanges() {
    //this.sideBarHandler()
  }

  sideBarHandler() {
    const output = document.getElementById('mapSubmenu');
    const output2 = document.getElementById('dashSubmenu');
    const output3 = document.getElementById('humanResourcesSubmenu');


    // Map submenu

    if (output) {
      if (this.activatedRoute.children[0].snapshot.url[0].path == "map") {
        output.style.display = "block"
        //output.classList.add('active')
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "msa-map") {
        output.style.display = "block"
        //output.classList.add('active')
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path === "workorders-without-coordinates") {
        output.style.display = "block"
        //output.classList.add('active')
      }
    }


    // Project submenu
    if (output2) {
      if (this.activatedRoute.children[0].snapshot.url[0].path == "dashboard") {
        output2.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "project") {
        output2.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "new-project") {
        output2.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "import-data") {
        output2.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "end-user-communication") {
        output2.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "weekly-planning") {
        output2.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path.includes("weekly-planning-detail")) {
        output2.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path.includes("weekly-planning-map")) {
        output2.style.display = "block"
      }

    }
    // Human resources submenu
    if (output3) {
      if (this.activatedRoute.children[0].snapshot.url[0].path == "human-resources") {
        output3.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "workers") {
        output3.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path == "teams") {
        output3.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path.includes("worker-detail")) {
        output3.style.display = "block"
      }
      if (this.activatedRoute.children[0].snapshot.url[0].path.includes("teams-detail")) {
        output3.style.display = "block"
      }
    }

  }
}
