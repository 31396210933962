import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStatesByType'
})
export class FilterStatesByTypePipe implements PipeTransform {
  transform(states: any[], type: number): any[] {
    if (!states || (type !== 0 && !type)) {
      return [];
    }
    return states.filter(state => state.type === type);
  }
}