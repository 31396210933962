import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, pipe, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { baseUrl } from '@environments/environment';
import { WorkOrder } from '@shared/models/work-order';
import { OrderGroup } from '@shared/models/order-group';
import { ToastService } from '@shared/services/toast/toast.service';
import { MSA } from '@shared/models/MSA';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ReportsService implements OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Makes a http get request to backend and returns one report by id
   * @param {number} id Takes id and requests work order by it
   * @returns one work order
   *
   */
  getReport(id: number): Observable<any> {
    const url = `${baseUrl}/api/reports/${id}`;
    let params: HttpParams = new HttpParams();

    // Get with options included
    return this.http.get<any>(url, { params: params })
        .pipe(
            map((data: any) => {
                return data['message'];
            }),
            catchError(this.handleError<any>(this.translateService.instant('services.getReportFailed') + id))
        );
}

    /**
     * Handles Http operations that failed.
     * Lets the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            //console.log(error)
            //console.log(`${operation} failed: ${error.message}`);

            this.toastService.sendToast(false, error.message)


            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}
