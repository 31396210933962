import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@core/auth/auth.service";

@Injectable({
    providedIn: 'root'
})

/**
 * A route guard for routes that require authentication.
 */
export class AuthGuard  {

    constructor(private authService: AuthService, private router: Router) {

    }

    /**
     * This method returns a boolean indicating whether or not navigation to a route should be allowed.
     * If the user isn’t authenticated, they are re-routed to some other place, in this case a route called /login.
     * @returns
     */
     canActivate(route: ActivatedRouteSnapshot) {
        const user = this.authService.isLoggedIn(false);
        if (user) {

            const userRole = this.authService.getRole()

            if (route.data.role && route.data.role == userRole) {
                // logged in so return true
                return true;
            }

            // user role does not have access to this route
            // confirm role from API, display error and try default route
            this.authService.setRole(() => {
                this.router.navigate([''])
            })
            return false;

        } else {
            // not logged in so redirect to login page with the return url
            // or if token timed out, refresh token and continue back to given route
            return this.authService.isLoggedIn(true, route);
        }
    }

    /**
     * This method
     * @param route
     * @param state
     * @returns
     */
    canActivateChild(route: ActivatedRouteSnapshot):  boolean {
        return this.canActivate(route);
   }
}
