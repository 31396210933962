import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FieldReportingService } from '@modules/field-reporting/services/field-reporting/field-reporting.service';
import { WorkOrderService } from '@modules/planner/services/workorder/work-order.service';
import { TranslateService } from '@ngx-translate/core';
import { icon, Icon } from 'leaflet';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';

declare const L: any;

@Component({
  selector: 'app-interrupt',
  templateUrl: './interrupt.component.html',
  styleUrls: ['./interrupt.component.scss']
})
export class InterruptComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject()

  id: any
  saveAdditional = false;

  interruptImgUrl: any
  interruptImgData: any

  map: any

  installationTime: any
  installationDate: Date = new Date()
  installationDateToApi: any
  installHours: any
  installMinutes: any
  timeUntouched = true
  switchedDate: any
  switchedTime: any
  dirtyTime = false

  latitude: any
  longitude: any
  location = true

  interruptReason: any
  additionalInfo: any

  constructor(
    private route: ActivatedRoute,
    private workorderService: WorkOrderService,
    private datePipe: DatePipe,
    private fieldReportingService: FieldReportingService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  defaultMarker: Icon = icon({
    iconUrl: 'assets/leaflet/location.svg',
    shadowUrl: 'assets/leaflet/marker-shadow.png',
    iconSize: [50, 50],
    shadowSize: [20, 20],
    iconAnchor: [25, 45],
    shadowAnchor: [10, 10]
  });

  ngOnInit(): void {
    this.route.paramMap.subscribe(pmap =>  {
      this.id = pmap.get('id')
      this.getMeterInformation(this.id)
      this.initMap()
    })
  }

  // Get meter information with correct id
  getMeterInformation(meterId) {
    this.workorderService.getWorkOrder(meterId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      data => {

          this.installHours = '00'
          this.installMinutes = '00'

      }
    )
  }

  onFileInput(e) {
    if(e.target.files) {
      let reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = (event:any) => {
          this.interruptImgUrl = event.target.result
          this.interruptImgData = e.target.files[0]
      }
    }
  }

  locationCheck() {
  
    // We return dont prompt geolocation if the user disables the location tab
    if(this.location) return

    this.map.locate({
      setView : true,
      maxZoom: 15
    }).on('locationfound', (e) => {
      var marker = L.marker([e.latitude, e.longitude], {icon: this.defaultMarker});
      this.latitude = e.latitude
      this.longitude = e.longitude
      this.map.addLayer(marker);
    }).on('locationerror', () => {
          alert(this.translateService.instant('fieldReporting.shared.locationAccessError'));
          this.location = false
    });
  }


  /**
   * Initializes the map with custom settings
   * 
   * TODO: Reverse geocoding to get location name
   */
   initMap() {
    // Sets initial canvas
    this.map = L.map('interruptmap', {
      editable: true,
      dragging: false,
      scrollWheelZoom: false,
    })

    // Sets the zoom +/- controller to topright corner of the map
    this.map.zoomControl.setPosition('topright')

    // OpenStreetMap integration
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      maxZoom: 19,
      minZoom: 5,
      detectRetina: true,      
    }).addTo(this.map)

    // Center on user on default, disable location if user doesn't give permission.
    this.map.locate({
      setView : true,
      maxZoom: 15
    }).on('locationfound', (e) => {
      var marker = L.marker([e.latitude, e.longitude], {icon: this.defaultMarker});
      this.latitude = e.latitude
      this.longitude = e.longitude
      this.map.addLayer(marker);
    }).on('locationerror', () => {
          alert(this.translateService.instant('fieldReporting.shared.locationAccessError'));
          this.location = false
    });
  }


  /**
   * This function is called when the user wants to make changes to installation time.
   * The time is "dirty" if it has been altered and this leaves a mark.
   */
   makeTimeDirty() {
    Swal.fire({
      title: this.translateService.instant('fieldReporting.shared.swal.adjust'),
      text: this.translateService.instant('fieldReporting.shared.swal.adjustText'),
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
      denyButtonText: this.translateService.instant('basic.no')
    }).then((result) => {
      if (result.isConfirmed) {
        this.timeUntouched = false
        const date = this.installationDate
        this.switchedDate = new Date(date).toISOString().split('T')[0]
        this.switchedTime = this.installHours+':'+this.installMinutes
      }
    })
  }

  checkValues() {
    this.saveAdditional = true;
    let areValuesSet = true;
    
    if (this.location && (this.latitude == undefined || this.longitude == undefined)){
      areValuesSet = false
      this.location = false
      return
    } 

    if (!this.interruptReason /*|| !this.additionalInfo*/) areValuesSet = false

    if (!this.interruptImgUrl ) {
      Swal.fire(
        this.translateService.instant('fieldReporting.interrupt.swal.imageText'),
        '',
        "warning"
      )
      areValuesSet = false
      return
    }

    // We check if installation time is untouched, if untouched this will be skipped.
    if(!this.timeUntouched) {
      if(!this.switchedTime) {
        Swal.fire(
          this.translateService.instant('fieldReporting.interrupt.swal.installationTime'),
          '',
          "warning"
        )
        areValuesSet = false
        return
      }
      // const date variable is the new datetime for installation time if installation time is "dirty"
      const date = new Date(this.switchedDate)
      let time = this.switchedTime.split(':')
      let hours = time[0]
      let minutes = time[1]

      date.setHours(0,0,0,0)
      date.setHours(hours, minutes, 0)

      this.installationDateToApi = date
    }

    if (areValuesSet) {
      this.saveAdditional = false
      this.interruptionToApi()
    } else {
      Swal.fire(
        this.translateService.instant('fieldReporting.interrupt.swal.checkFields'),
        '',
        "warning"
      )
    }
  }
  
  interruptionToApi() {
    const formData = new FormData()
    if (this.additionalInfo === undefined) this.additionalInfo = null
    // TODO: INTERRUPT REASON TO jsonData ASWELL!! , NO FIELD IN DB YET
    // -> is in JsonData, maybe done already
    let jsonData = {
      "interrupt_reason": this.interruptReason,
      "notes": this.additionalInfo,
      "status": 6,
      "attend_time": this.datePipe.transform(this.installationDateToApi, 'yyyy-MM-dd HH:mm')
    }

    let imgData = [{
      "image_place": 9,
      "title": this.interruptImgData.name,
      "image_key": 'img9'
    }]

    if(this.location) {
      let coordinateData =  {
        'coordinates': `{ "lat": ${this.latitude}, "lon": ${this.longitude} }`
      }
      jsonData = Object.assign(jsonData, coordinateData)

    }
    formData.append('img9', this.interruptImgData as any)
    formData.append('images', JSON.stringify(imgData) as any)
    formData.append('reportdata',  JSON.stringify(jsonData) as any)

    this.fieldReportingService.reportWorkorder(this.id, formData)
      .subscribe(
        result => {
          if(result) {
            Swal.fire({
              title: this.translateService.instant('fieldReporting.shared.swal.success'),
              text: this.translateService.instant('fieldReporting.interrupt.swal.interruptSuccess'),
              icon: 'success',
              confirmButtonColor: '#3085d6',

            }).then((result) => {
              if (result.isConfirmed || result.isDismissed) {
                this.backToDashboard()
              }
            })
          } else {
            Swal.fire(
              this.translateService.instant('fieldReporting.interrupt.swal.sendError'),
              this.translateService.instant('fieldReporting.shared.swal.tryAgain'),
              "error"
            )
          }
        }
      );
  }

  backToDashboard() {
    const queryParamsValue: Params = { meterId: this.id };
    this.router.navigate(['field-reporting/dashboard'],{
      queryParams: queryParamsValue,
      queryParamsHandling: 'merge'
    })
  }
}
