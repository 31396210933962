import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { finalize, forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { Worker } from '@shared/models/worker';
import { TeamsService } from '@modules/planner/services/teams/teams.service';
import { ToastService } from '@shared/services/toast/toast.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit, OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  allStates: Array<any> = Array()
  teams: Array<any> = Array()
  originalTeams: Array<any> = Array()
  name: string = ""
  pageSpinner: boolean = true;
  userLang:any = null;

  // Create team
  createTeamName: string = ""
  selectedState: any = ""
  teamStates: Array<any> = Array()
  allWorkers: Array<Worker> = Array()
  originalWorkers: Array<Worker> = Array()
  selectedWorkers: Array<any> = Array()
  currentTeamEfficiency: number = 0
  colorOuter = '#FF0000'
  colorInner = '#ffcccb'
  popupSpinner: boolean = false
  showTotalEfficiency: boolean = false

  constructor(
    private teamsService: TeamsService,
    private workers: WorkersService,
    private toastService: ToastService,
    private router: Router,
    private translateService: TranslateService
  ) { }



  /**
   * Get teams data and get workers data.
   * Runs when component is loaded.
   * @author Jesse Lindholm
   */
  ngOnInit(): void {
    this.userLang = localStorage.getItem('userlanguage');
    this.getStatuses()
  }

  /**
   * Destroy subscriptions to free space.
   * Runs when moved away from component.
   * @author Jesse Lindholm
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  /**
   * Get teams from api call. Assign information correctly to be displayed in HTML. Set name, initials and skills. Also
   * set skill to allSkills to be displayed in filter skills selection box
   * @author Jesse Lindholm
   */
  getTeams() {
    this.teamsService.getTeams()
     .pipe(takeUntil(this.componentDestroyed$))
     .subscribe(
      data => {
        let finalTeams = Array()
        for (let j = 0; j < Object.keys(data).length; j++) {
          let originalObject = data[j]
          originalObject.skills = Array()
          originalObject.finalWorkers = Array()
          originalObject.efficiencyNumber = parseFloat(originalObject.efficiency)
          if (originalObject.hasOwnProperty('workers')) {
            // Go through workers in workerteam, setting name, initials and skills
            for (let i = 0; i < Object.keys(originalObject.workers).length; i++) {
              let object = <any>Object.values(originalObject.workers)[i]
              object.id = Object.keys(originalObject.workers)[i]
              object.name = object.firstname + " " + object.lastname
              object.initials = object.firstname.charAt(0) + object.lastname.charAt(0)
              object.efficiencyNumber = parseFloat(object.efficiency)
              let skills = Array();
              skills = this.returnSkills(object.qualifications)
              skills.forEach(element => {
                if (originalObject.skills.length > 0) {
                  if (!originalObject.skills.some(s => s.definition == element.definition))  originalObject.skills.push(element)
                } else originalObject.skills.push(element)
              })
              object.skills = skills
              originalObject.finalWorkers.push(object)
            }
          }
          finalTeams.push(originalObject)
        }
        this.teams = finalTeams;
        this.originalTeams = JSON.parse(JSON.stringify(finalTeams))
        this.pageSpinner = false
      }
    )
  }


    getStatuses() {
        this.teamsService.getStatuses()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    this.teamStates = data

                    for (let i = 0; i < this.teamStates.length; i++) {
                        let definition
                        let defjson = JSON.parse(this.teamStates[i].definition) || {}
                        if(this.userLang) {
                            definition = defjson[this.userLang] ||
                                defjson['en'] ||
                                undefined;
                        } else {
                            definition = defjson['en'] || undefined;
                        }
                        this.teamStates[i].definition = definition
                        if (this.teamStates[i].state !== 1)  this.allStates.push({id: this.teamStates[i].id, definition: definition})
                    }
                    this.getTeams()
                    this.getWorkers()
                    this.stateChange(this.teamStates[0].id)
                }
            )
    }

  /**
   * Go through parsed qualifications and add skills to allSkills. Also return skills so that they can be assigned to team or worker.
   * @param skills usually data.qualifications, skills of worker or team
   * @returns skills in proper format
   * @author Jesse Lindholm
   */
  returnSkills(skills) {
    let skillsToReturn = Array()
    // If not null
    if (skills != null) {
      // Parse JSON
      try {
        let parsedQualifications = JSON.parse(skills)
        skillsToReturn = this.allStates.filter(skill => parsedQualifications.includes(skill.id));
      } catch (e) {
        // console.log(e)
      }
    }
    return skillsToReturn
  }

  /**
   * Get data of workers.
   * @author Jesse Lindholm
   */
  getWorkers() {
    this.workers.getWorkers()
     .pipe(takeUntil(this.componentDestroyed$))
     .subscribe(
      data => {
          for (let key in data) {
              let value = data[key]
              value.efficiencyNumber = parseFloat(value.efficiency)
              value.skills = this.returnSkills(value.qualifications)
              this.originalWorkers.push(value)
          }
      }
    )
  }

  /**
   * Navigate to correct team page.
   * @author Jesse Lindholm
   */
  goToTeamDetail(id) {
    this.router.navigate(['/planner/teams-detail/' + id])
  }

  /**
   * Function for making a new list with selected state in workerteams.
   * @param value skill
   * @author Jesse Lindholm
   */
  filterStateChanged(value) {
    if (value != 'undefined') {
      value = parseInt(value)
      let newList = Array()
      for (let i = 0; i < this.originalTeams.length; i++) {
          if (this.originalTeams[i].status_id == value) newList.push(this.originalTeams[i])
      }
      this.teams = newList;
    }
    else {
      this.teams = this.originalTeams;
    }
  }

  /**
   * Saving function for workerteam. First make team with given name. Name is required before clicking submit. Set workerteamId from return and
   * use it in linking selected workers to correct workerteam. If we get no workerteamId send toast, telling that something failed. Use
   * forkJoin to put together all api calls for workers. After they have completed reset teams array resetting interface and get new teams with
   * added team. Also reset createTeamName and selectedWorkers array.
   * @author Jesse Lindholm
   */
  createNewTeam() {
    this.pageSpinner = true
    this.popupSpinner = true
    let workerteamId: number | null = null;
    this.teamsService.createTeam(this.createTeamName, this.selectedState).pipe(
      finalize(() => {
        if (workerteamId) {
          let observables: Observable<any>[] = [];
          for (let i = 0; i < this.selectedWorkers.length; i++) {
            observables.push(this.workers.setWorkerToWorkerteam(this.selectedWorkers[i].id, workerteamId))
          }
          forkJoin(observables)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            () => {
              this.teams = Array()
              for (let i = 0; i < this.selectedWorkers.length; i++) {
                this.selectedWorkers[i].checked = false
              }
              this.getTeams()
              document.getElementById('hiddenClose')?.click()
            }
          )
          if (observables.length == 0) {
            this.getTeams()
            document.getElementById('hiddenClose')?.click()
          }
        } else {
          this.toastService.sendToast(false, this.translateService.instant('teams.teamSaveError'))
          document.getElementById('hiddenClose')?.click()
          this.pageSpinner = false
        }


      }),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe(
      data => {
        if (typeof data == 'number') workerteamId = data
        else workerteamId = null
      }
    )
  }

  /**
   * Find correct worker with id.
   * Check if it is checked or not, pushing it to selectedWorkers array if its checked.
   * If not checked remove from selectedWorkers array
   * Calculate total efficiency and display it at bottom of popup if it is more than zero.
   * Use break in for loops because there might be a lot of workers in one project.
   * Transform number to fixed 2 decimal number.
   * @param id id of clicked worker
   * @author Jesse Lindholm
   */
  workerClicked(id) {
    this.showTotalEfficiency = false
    let workerCount: number = 0.00;
    let efficiencyTotal: number = 0.00;
    for (let j = 0; j < this.allWorkers.length; j++) {
      if (this.allWorkers[j].id == id) {
        if (this.allWorkers[j].checked == false || this.allWorkers[j].checked == undefined) {
          this.allWorkers[j].checked = true
          this.selectedWorkers.push(this.allWorkers[j])
        } else {
          this.allWorkers[j].checked = false
          for (let i = 0; i < this.selectedWorkers.length; i++) {
            if (this.selectedWorkers[i].id == id) {
              this.selectedWorkers.splice(i, 1)
              break;
            }
          }
        }
        break;
      }
    }
    if (this.selectedWorkers.length == 0) this.currentTeamEfficiency = 0
    else {
      for (let i = 0; i < this.selectedWorkers.length; i++) {
        workerCount++;
        efficiencyTotal = efficiencyTotal + parseFloat(this.selectedWorkers[i].efficiency)
      }
      if (workerCount != 0 && efficiencyTotal != 0) {
        let total = efficiencyTotal / workerCount
        this.currentTeamEfficiency = parseFloat(total.toFixed(2))
      }
    }
    setTimeout(() => {
      this.showTotalEfficiency = true
    }, 100);

  }

  /**
   * Cancel creation of workerteam.
   * Reset values.
   * @author Jesse Lindholm
   */
  cancelCreateTeam() {
    this.createTeamName = ""
    this.currentTeamEfficiency = 0
    for (let i = 0; i < this.selectedWorkers.length; i++) {
      this.selectedWorkers[i].checked = false
    }
    this.selectedWorkers = Array()
    this.popupSpinner = false
  }

    stateChange(id) {
      id = parseInt(id)
        if(id) {
            this.selectedState = id
            this.allWorkers = this.originalWorkers.filter(worker => worker.skills.some(skill => skill.id === id));
        }
    }
}
