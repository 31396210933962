import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@shared/models/languages';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import {finalize, Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import languages from 'assets/i18n/language-list.json'

@Component({
  selector: 'app-planner-detail',
  templateUrl: './planner-detail.component.html',
  styleUrls: ['./planner-detail.component.scss']
})
export class PlannerDetailComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject()
  name;
  currentProjectId = 0;
  projects = Array();
  selectedProjects = Array();
  unselectedProjects = Array();
  plannerLanguage: Language = {databaseLanguage: "", displayName: ""};
  email = null;
  userId;
  username;
  newUsername: any = null;
  newPassword = null;
  showUsername = true;
  showPassword = true;
  createUser = true;
  personAccount = 'assets/icons/person_gray_24dp.svg';
  createUserActive = true;
  isWindowTooSmallBasic: boolean = false
  isWindowTooSmallEdit: boolean = false
  showGeneratedPassword: boolean = false
  accountDisabled: boolean = false
  editMode1 = false; // Email, language
  editMode2 = false; // Projects
  editMode3 = false; // Account


  // New interface language
  tempLanguage: Language = {databaseLanguage: "", displayName: ""}
  tempEmail: any = null;
  interfaceLanguages: Array<Language> = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private workersService: WorkersService, // used to disable/enable accounts, check usernames
    private toastService: ToastService,
    private translateService: TranslateService
    ) { }

  // Get correct userId from url route
  ngOnInit(): void {
    this.route.paramMap.subscribe(pmap =>  this.getPlanner(pmap.get('id')));
    if (window.innerWidth < 950) {
      this.isWindowTooSmallBasic = true
      this.isWindowTooSmallEdit = true
    } else if (window.innerWidth < 1455) this.isWindowTooSmallEdit = true
    this.translateService.get('basic.weekDays.monday').subscribe(
      () => this.getTranslations()
    )
    this.userService.getUserInfo().subscribe((data) => {
      this.currentProjectId = data.current_project;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 950) this.isWindowTooSmallBasic = true
    else this.isWindowTooSmallBasic = false
    if (window.innerWidth < 1455) this.isWindowTooSmallEdit = true
    else this.isWindowTooSmallEdit = false
  }

  getTranslations() {
    languages.languages.forEach(language => {
      if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`)})
      else this.interfaceLanguages.push({databaseLanguage: language, displayName: `No translation (${language})`})
    })
  }

  /**
   * This function is base for all data in single planner page. We get the data
   * from api call and put them to variables to be able to use them in HTML
   * @param id planners id to perform api call with
   */
  getPlanner(id) {
    this.userService.getUserInfoWithId(id).subscribe(
      data => {
          this.userId = data.id;
          this.name = data.firstname + " " + data.lastname

          this.email = data.email;

        if (data.disabled == 1) this.accountDisabled = true
        else this.accountDisabled = false
        this.username = data.username
        if (this.username != null) {
          this.createUser = false;
          if (data.disabled == 0) this.personAccount = 'assets/icons/person_blue_24dp.svg';
          this.createUserActive = false;
        }
        if (data.language) {
          for (let i = 0; i < this.interfaceLanguages.length; i++) {
            const language = this.interfaceLanguages[i]
            if (language.databaseLanguage === data.language.toLowerCase()) this.plannerLanguage = language
          }
        }

          let property: keyof typeof data.projects;
          this.projects = Array()
          this.unselectedProjects = Array()
          this.selectedProjects = Array()
          this.userService.getAllProjects()
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                  allProjects => {
                      this.unselectedProjects = allProjects

                      for (property in data.projects) {
                          this.projects.push({id: property, name: data.projects[property]})
                          if(this.unselectedProjects.find(e => e.id == property)) {
                              this.selectProject(property)
                          }
                      }
                  }
              )
      }
    )
  }

  /**
   * Navigation back to workers page. Function is called when "back" is pressed in interface
   */
  toPlannersPage() {
    this.router.navigate(['planner/planner-management'])
  }

  /**
   * Function is called when user presses edit button in interface
   */
  toggleEdit(tab) {
    if (tab == 1) {
      this.editMode1 = true;
      this.tempEmail = this.email;
      this.tempLanguage = this.plannerLanguage
    }
    if (tab == 2) {
      this.editMode2 = true;
    }
    if (tab == 3) {
      this.editMode3 = true;
    }

  }

  // Most important function for saving changes after user made them and
  // Sending them to database
  saveEdit(tab) {
    // Make a big api call with all the changes
    if (tab == 1) {
    // Check if email has been changed and fire pop up for confirmation if it has been changed.
      if (this.email != this.tempEmail) {
        this.userService.updateUserEmail(this.userId, this.email)
      }
      if (this.plannerLanguage.databaseLanguage !== this.tempLanguage.databaseLanguage) {
        this.userService.updateUserLanguage(this.userId, this.plannerLanguage.databaseLanguage)
      }
      this.editMode1 = false;
    }
    else if(tab == 2) {
        var keys = this.projects.map(({ id }) => id);
        for(var i = 0; i < this.selectedProjects.length; i++) {
            let project = this.selectedProjects[i]

            if(keys.includes(project.id.toString())) {
                // console.log('key '+project.id+' already in projects. Skip')
            } else {
                // console.log('key '+project.id+' not in projects. Add')
                this.userService.setProjectToUser(this.userId, project.id).subscribe(
                    data => {
                        // console.log(data)
                    }
                )
            }
        }
        for(var i = 0; i < this.unselectedProjects.length; i++) {
            let project = this.unselectedProjects[i]

            if(keys.includes(project.id.toString())) {
                // console.log('key '+project.id+' in unselectedProjects. Remove')
                this.userService.removeProjectFromUser(this.userId, project.id).subscribe(
                    data => {
                        // console.log(data)
                    }
                )
            }
        }
        this.editMode2 = false;
        this.getPlanner(this.userId)
    } else if (tab == 3) {
      if (this.newUsername != null) {
        let response;
        if (this.newUsername.trim() != "") {
          this.workersService.checkUsername(this.newUsername)
          .pipe(
            finalize(() => {
              if (response != "username_in_use") {
                // this.userService.updateUserUsernamePassword(this.userId,this.newUsername, this.newPassword)
                this.username = this.newUsername;
                this.createUserActive = false;
                this.showUsername = true;
                this.showPassword = true;
                this.editMode3 = false;
                this.accountDisabled = false
              }
              this.newUsername = null;
              this.newPassword = null;
            })
          )
          .subscribe(
            data => response = data
          )
        } else {
          this.toastService.sendToast(false, this.translateService.instant('basic.usernameSpacebars'))
          this.newUsername = null;
          this.newPassword = null;
        }
      }
      if (this.newUsername == null && this.newPassword != null) {
        // this.userService.updateUserUsernamePassword(this.userId,this.newUsername, this.newPassword)
        this.newUsername = null;
        this.newPassword = null;
        this.editMode3 = false;
        this.showUsername = true;
        this.showPassword = true;
        this.createUserActive = false;
      }
      if (this.showGeneratedPassword) this.showGeneratedPassword = false


    }

  }

  /**
   * Logic for cancelling edit by user. Apply old values to all places that could have been changed.
   */
  cancelEdit(tab) {
    if (tab == 1) {
      this.editMode1 = false;
      this.email = this.tempEmail;
      this.plannerLanguage = this.tempLanguage
    }
    if(tab == 2) {
        this.editMode2 = false;
    }

    if (tab == 3) {
      if (this.createUserActive) {
        this.createUser = true
        this.personAccount = 'assets/icons/person_gray_24dp.svg';
      }
      this.editMode3 = false;
      this.showUsername = true;
      this.showPassword = true;
      this.newPassword = null;
      this.newUsername = null;
      if (this.showGeneratedPassword) this.showGeneratedPassword = false
    }
  }

  changeUsernameToggle() {
    this.showUsername = false;
  }

  changePasswordToggle() {
    this.showPassword = false;
  }

  generatePassword() {
    this.newPassword = uuidv4()
  }

  createAccount() {
    this.personAccount = 'assets/icons/person_blue_24dp.svg';
    this.createUser = false;
    this.editMode3 = true;
    this.showPassword = false;
    this.showUsername = false;
  }

  hideShowPassword() {
    let passwordField = document.getElementById("login-password");

    this.showGeneratedPassword = !this.showGeneratedPassword

    const type = passwordField!.getAttribute("type") === "password" ? "text" : "password";
    passwordField!.setAttribute("type", type);
  }

  disableAccount() {
    Swal.fire({
      title: this.translateService.instant('planner.workerDetail.disableAccountConfirmation'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('basic.yes'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.workersService.changeDisabledStatus(1, this.userId).subscribe()
        this.accountDisabled = true
        this.personAccount = 'assets/icons/person_gray_24dp.svg';
      }
    })
  }

  activateAccount() {
    this.workersService.changeDisabledStatus(0, this.userId).subscribe()
    this.accountDisabled = false
    this.personAccount = 'assets/icons/person_blue_24dp.svg';
  }

  languageChange(databaseLanguage) {
    for (let i = 0; i < this.interfaceLanguages.length; i++) {
      const language = this.interfaceLanguages[i];
      if (language.databaseLanguage === databaseLanguage) this.plannerLanguage = language
    }
  }

    selectProject(id) {
        if(id) {
            for(let i = 0; i < this.unselectedProjects.length; i++) {
                let project = this.unselectedProjects[i];
                if (project.id == id) {
                    this.selectedProjects.push(project);
                    this.unselectedProjects.splice(i, 1);
                    break
                }
            }
        }
    }

    unselectProject(id) {
        if(id) {
            for(let i = 0; i < this.selectedProjects.length; i++) {
                let project = this.selectedProjects[i];
                if (project.id == id) {
                    this.unselectedProjects.push(project);
                    this.selectedProjects.splice(i, 1);
                    break
                }
            }
            this.unselectedProjects.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
    }
}
