import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '@core/auth/auth.service';
import { baseUrl, integUrl } from "@environments/environment";


/**
 * This interceptor handles the applications response to a missing JWT token.
 * Uses refreshTokenSubject to track the current refresh token value. It is null if no token is currently available.
 * For example, when the refresh progress is processing (isRefreshing = true),
 *  we will wait until refreshTokenSubject has a non-null value (new Access Token is ready and we can retry the request again)
 */

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private isRefreshing = false
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public auth: AuthService, private router: Router) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // handle successful responses here
          }
        }),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              return this.handle401Error(request, next);
            }
          }
          return throwError(err);
        })
      );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = request.url.startsWith(baseUrl) ? this.auth.getRefreshToken() : this.auth.getIntegrationRefreshToken();

      const refreshObservable = this.auth.refreshToken(refreshToken);
      // const refreshObservable = request.url.startsWith(baseUrl) ? this.auth.refreshToken(refreshToken) : this.auth.refreshIntegrationToken();

      return refreshObservable.pipe(
        tap(data => {
          this.isRefreshing = false;

          if (request.url.startsWith(baseUrl)) {
            this.auth.storeToken(data);
          } else {
            // this.auth.storeIntegrationToken(data);
          }
          this.refreshTokenSubject.next(data);
        }),
        switchMap(data => {
          const token = this.auth.getRefreshToken();
          // const token = request.url.startsWith(baseUrl) ? this.auth.getRefreshToken() : this.auth.getIntegrationRefreshToken();

          if (token) {
            const updatedRequest = this.addTokenHeader(request, token);
            return next.handle(updatedRequest);
          } else {
            // Handle the case where no token is available, for example:
            this.auth.logout();
            return throwError('No token found');
          }
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.auth.logout();
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          const updatedRequest = this.addTokenHeader(request, token);
          return next.handle(updatedRequest);
        })
      );
    }
  }


  private addTokenHeader(request: HttpRequest<any>, token: string) {

    return request.clone(
      {
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
  }

}
