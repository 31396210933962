import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarsService } from '@modules/planner/services/calendars/calendars.service';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@shared/services/user/user.service';
import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Subject, takeUntil } from "rxjs";
import { PlannerProjectService } from "@modules/planner/services/planner-project/planner-project.service";
import { WorkOrderService } from "@modules/planner/services/workorder/work-order.service";
import { TeamsService } from "@modules/planner/services/teams/teams.service";
import { ToastService } from "@shared/services/toast/toast.service";

@Component({
  selector: 'app-schedule',
  templateUrl: './project-schedule.component.html',
  styleUrls: ['./project-schedule.component.scss'],
  providers: [HttpClient]
})
export class ProjectScheduleComponent implements OnInit {

  componentDestroyed$: Subject<boolean> = new Subject()
  userLang: any = null;
  hasEstimates: boolean = false
  projectId: any
  schedule: any
  allStates: any
  pageLoading = true
  todaysDate: any
  today: any
  projectData: any
  projectName: any

  constructor(
    private http: HttpClient,
    private calendars: CalendarsService,
    private router: Router,
    private datePipe: DatePipe,
    private workersService: WorkersService,
    private userService: UserService,
    private translateService: TranslateService,
    private plannerProjectService: PlannerProjectService,
    private workOrderService: WorkOrderService,
    private teamsService: TeamsService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.userLang = localStorage.getItem('userlanguage');
    this.todaysDate = new Date()
    this.today = this.todaysDate.getDay()
    // this.getCalendarsInit()

    this.getCurrentProjectId(() =>
      this.plannerProjectService.getProjectById(this.projectId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.projectData = data
          this.projectName = data.name

          // todo hae jostain toisesta endpointista ja tuo vanhat arvot
          this.plannerProjectService.getSchedule()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
              data => {
                if (data) {
                  this.schedule = data
                  this.hasEstimates = true
                }
                this.pageLoading = false
              }
            )
        })
        .add(() => {
          // this.getMsaEstimates()
        })
    )

  }

  /**
   * Gets current project id and calls a callback method to tell that it has finished
   * @param cb
   */
  getCurrentProjectId(cb) {
    this.userService.getUserInfo().subscribe(
      data => {
        this.projectId = data.current_project
        // this.userid = data.id

        // cb() = callback to let the other function know that this one is finished
        cb()
      }
    );
  }

  calculate() {
    let first = true
    let previousEndDate = ""
    let previousStartDate = ""
    for (let state of this.filterFirstAndLastStates(this.schedule.states)) {
      if (first) {
        if (!state.startdate) {
          // this.toastService.sendToast(false, this.translateService.instant('schedule.setstartdate'))
          // break
            let today = new Date()
            state.startdate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
        }
      }

      // calculate days needed
      let totalMinutes = state.workorderlength * this.schedule.variables.workorders
      let timeNeeded = Math.ceil(totalMinutes / state.groups)
      let daysNeeded = Math.ceil(timeNeeded / this.schedule.variables.daylength);

      if (!first) {
          if(!state.manual) {
              // earliest possible start date is previous starting date + 1 day
              let earliestDate = new Date(previousStartDate);
              // earliestDate.setDate(earliestDate.getDate() + 1);
              earliestDate.setDate(earliestDate.getDate() + 1 + (earliestDate.getDay() == 5 ? 2 : earliestDate.getDay() == 6 ? 1 : 0));

              // calculated start date is previous end date + 1 day, minus days needed
              let calculatedStartDate = new Date(previousEndDate);
              calculatedStartDate.setDate(calculatedStartDate.getDate() + 1 + (calculatedStartDate.getDay() == 5 ? 2 : calculatedStartDate.getDay() == 6 ? 1 : 0));
              //calculatedStartDate.setDate(calculatedStartDate.getDate() - daysNeeded + 1);
              let daysRemoved = 0;
              while (daysRemoved < daysNeeded) {
                  calculatedStartDate.setDate(calculatedStartDate.getDate() - 1);
                  var dayOfWeek = calculatedStartDate.getDay();
                  if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                      daysRemoved++;
                  }
              }

              // use whichever is later
              state.startdate = earliestDate > calculatedStartDate ? earliestDate : calculatedStartDate
              state.startdate = `${state.startdate.getFullYear()}-${String(state.startdate.getMonth() + 1).padStart(2, '0')}-${String(state.startdate.getDate()).padStart(2, '0')}`;

          }
        }
      previousStartDate = state.startdate

      // calculate ending date
      let endDate = new Date(state.startdate);
      // endDate.setDate(endDate.getDate() + daysNeeded);

      let daysCounted = 0;
      while (daysCounted < daysNeeded) {
          endDate.setDate(endDate.getDate() + 1);
            var dayOfWeek = endDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                daysCounted++;
            }
        }

      // todo remove weekends by default
      // let d = new Date(currentDate)
      // var day = d.getDay();
      // let test = d.setDate(d.getDate() + daysNeeded + (day === 6 ? 2 : +!day) + (Math.floor((daysNeeded - 1 + (day % 6 || 1)) / 5) * 2));
      //
      // let dd = new Date(test)
      // let formattedD = `${dd.getFullYear()}-${String(dd.getMonth() + 1).padStart(2, '0')}-${String(dd.getDate()).padStart(2, '0')}`;

      // console.log('testdate', formattedD)

      let formattedDate = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`;
      previousEndDate = formattedDate
      state.deadline = formattedDate;

      first = false
    }
    if (!first) {
      this.toastService.sendToast(true, this.translateService.instant('schedule.calculated'))
    }
    return true
  }
  save() {
    //confirm save
    //save current state dates and group amount to project variables
  }

  /**
   * Converts a 2022-05-01 datestring to 01.05.2022 datestring.
   * @param datestring
   * @returns
   */
  dashesToDots(datestring) {
    var newdate = datestring.split("-").reverse().join("-")

    newdate = newdate.replace(/\-/g, '.')

    return newdate
  }

  filterFirstAndLastStates(states: any[]): any[] {
    if (states && states.length > 1) {
      return states.slice(1, -1);
    }
    return [];
  }

    dateChangedManual(event: Event, state) {
        // @ts-ignore
        const dateString = event.target.value;
        const date = new Date(dateString);

        // Check if date is valid
        if (!isNaN(date.getTime())) {
            // The date is valid
            state.manual = true;
            state.inputStyle = { 'border': '2px solid green' };
        } else {
            // The date is not valid
            state.manual = false;
            state.inputStyle = {};
        }

    }
}



