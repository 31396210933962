import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldReportingComponent } from '@layout/field-reporting/field-reporting.component';
import { SharedModule } from '@shared/shared.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReportComponent } from './components/report/report.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSliderModule } from 'ngx-slider-v2';
import { InterruptComponent } from './components/interrupt/interrupt.component';
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';



@NgModule({
  declarations: [
    FieldReportingComponent,
    DashboardComponent,
    ReportComponent,
    InterruptComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule,
    FormsModule,
    NgbModule,
    NgxSliderModule,
    BarcodeScannerLivestreamModule
  ]
})
export class FieldReportingModule { }
