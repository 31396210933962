import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor() { }


  /**
   * Calls to loadscript which loads the chosen script dynamically
   */
   ngOnInit() {
    this.loadScript("assets/js/sidebar.js")
  }

  /**
   * Handles the dynamic loading of main.js that mazer provides.
   * Main.js controls the behaviour of our main/left side navigation.
   * If we load this in app.component, it will display console errors on pages such as login. (because there is no mazer navigation present)
   * @param url
   */
  public loadScript(url) {
    let node = document.createElement('script')
    node.src = url
    node.type = 'text/javascript'
    document.getElementsByTagName('head')[0].appendChild(node)
    /*
    var w = window.innerWidth;
    if(w < 1200) {
        document.getElementById('sidebar')?.classList.remove('active');
        document.getElementById('main')?.classList.remove('main-with-sidebar');
    }else{
        document.getElementById('sidebar')?.classList.add('active');
        document.getElementById('main')?.classList.add('main-with-sidebar');
    }
    */
  }
}
