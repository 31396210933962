<nav id="sidebar" class="active">
  <div class="sidebar-wrapper active sidenavstyle">
    <div class="sidebar-menu">
      <ul class="menu">
        <!-- <li class="sidebar-title">Menu</li> -->
        <!-- Link to Project Dashboard -->
        <li routerLinkActive="active" class="sidebar-item">
          <a id="nav-odashboard" routerLink="dashboard" class='sidebar-link'>
            <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
            <span>{{'sideNavigation.titleDashboard' | translate }}</span>
          </a>
        </li>
      </ul>
    </div>
    <button class="sidebar-toggler btn x"><i data-feather="x"></i></button>
  </div>
</nav>
