<!-- Progress dashboard starts -->
<div class="page-container">
  <div class="page-heading">
    <h3>
      <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
      <span class="page-title">{{'sideNavigation.titleDashboard' | translate}}</span>
    </h3>
      <div class="col-auto">
          <img class="card-icon" src="assets/icons/info_blue_24dp.svg" (mouseover)="showColors = true" (mouseout)="showColors = false">
          <div *ngIf="showColors" class="colorbox">
              <div>
                  <span class="dot" [ngStyle]="{'background-color': interruptedColor}"></span>
                  {{'basic.interrupted' | translate}}
              </div>
              <div>
                  <span class="dot" style="background-color: white; border: 1px solid red"></span>
                  {{'basic.deviation' | translate}}
              </div>
              <div *ngFor="let status of allStates.statuses">
                  <span class="dot" [ngStyle]="{'background-color': status.color}"></span>
                  {{ status.definition }}
              </div>
          </div>

      </div>
  </div>
  <div class="page-content">

    <div class="map-height">
      <div id="mapProject" class="map-container structure-child"></div>
    </div>
    <div class="rightsidebar" *ngIf="showSidebar">
      <div class="sidebarcontent">
          <img src="assets/icons/close_red_24dp.svg" class="close-btn" (click)="closeSidebar()" />
          <h2 *ngIf="selectedMarker && selectedMarker.identifier">
            <span *ngIf="customerId === 2">{{ selectedMarker.transformer }} - {{selectedMarker.inverter}} - {{selectedMarker.circuit}}</span>
            <span *ngIf="customerId !== 2">{{selectedMarker.id}}</span>
          </h2>
          <h2 *ngIf="!selectedMarker.identifier && selectedMarker.inverter">{{ selectedMarker.transformer }} - {{selectedMarker.inverter}}</h2>

          <div *ngIf="selectedMarker.status_id">{{ 'planner.projectDashboard.currentPhase' | translate }}: {{getStatusDefinition()}}</div>

          <div *ngIf="selectedMarker.images">
              <img *ngFor="let img of selectedMarker.images" src="{{img.url}}" alt="{{img.title}}" class="thumbnail" (click)="selectedImage = img.url" />
          </div>
          <hr>
          <div>
              <h4>{{ 'planner.projectDashboard.reports' | translate }}</h4>
              <div *ngFor="let report of selectedMarker.reports">
                  <span class="dot" style="margin-left: 6px;" [ngStyle]="{'background-color': allStates.states[report.status_id]?.color}"></span>
                  {{report.created | date: 'dd.MM.yyyy HH:MM' }}
                  <br>
                  <img class="person-icon" src="assets/icons/person_blue_24dp.svg"> {{report.firstname}} {{report.lastname}}
              </div>
          </div>
          <h4>{{ 'planner.projectDashboard.images' | translate }} (
            <span *ngIf="selectedMarker.images">{{selectedMarker.imagecount}}</span>
            <span *ngIf="!selectedMarker.images">0</span>
          )</h4>
          <div *ngFor="let state of allStates.states | filterStatesByType: type">
              <button type="button" class="accordion" (click)="state.show = !state.show">
                  <span class="dot" [ngStyle]="{'background-color': state.color}"></span>
                  {{ state.definition }} ({{ selectedMarker?.statuses?.[state.id]?.count || 0 }})
                  <img [hidden]="!state.show" src="assets/icons/expand_less_black_24dp.svg"/>
                  <img [hidden]="state.show"  src="assets/icons/expand_more_black_24dp.svg"/>
              </button>
              <div *ngIf="selectedMarker?.statuses?.[state.id]?.images && state.show" class="panel">
                  <img *ngFor="let img of selectedMarker.statuses[state.id].images" src="{{img.url}}" alt="{{img.title}}" class="thumbnail" (click)="selectedImage = img.url" />
              </div>
          </div>
          <hr>
      </div>
  </div>

    <div class="justify-content-center w-100 d-flex" *ngIf="pageLoading">
      <load-spinner *ngIf="pageLoading"></load-spinner>
    </div>
    <section class="section" *ngIf="!pageLoading">
      <h4>{{'planner.projectDashboard.titleStages' | translate}}</h4>
      <div class="card shadow-sm " *ngFor="let state of filterNotStartedState(allStates.states)">
        <div class="row ml-1 mb-1">
            <div class="col-4">
                <h4><span class="dot" [ngStyle]="{'background-color': state.color}"></span> {{state.definition}}</h4>
            </div>
            <div class="col-4">
                <h4>{{'planner.projectDashboard.wholeProject' | translate}}</h4>
                <div>
                    {{'basic.done' | translate }}: <span class="green-text">{{state.workorders.totaldone}}/{{allStates.total}}</span>
                    <div class="progress progress-primary">
                        <div class="progress-bar" role="progressbar"
                             [ngStyle]="setProgress(state.workorders.totaldone, allStates.total, '#10A231')" aria-valuenow="0"
                             aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>
                <div>
                    {{'planner.projectDashboard.interrupted' | translate }}: <span [ngClass]="state.workorders.totalinterrupted > 0 ? 'red-text' : '' ">{{state.workorders.totalinterrupted}}/{{allStates.total}}</span>
                    <div class="progress progress-primary">
                        <div class="progress-bar" role="progressbar"
                             [ngStyle]="setProgress(state.workorders.totalinterrupted, allStates.total, '#bd2828')" aria-valuenow="0"
                             aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
    </section>
  </div>
</div>
<!-- Project dashboard ends -->
