import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-human-resources',
  templateUrl: './human-resources.component.html',
  styleUrls: ['./human-resources.component.scss']
})
export class HumanResourcesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  /**
   * 12.12.2022
   * Navigate to workers or teams page
   * @param destination name of landing page
   * @author Jesse Lindholm
   */
  navigate(destination: string) {
    if (destination === 'workers' || destination === 'teams') this.router.navigate([`/planner/${destination}`])
  }

}
