<div class="page-container">
    <div class="page-heading">
        <h3>
            <img src="assets/icons/person_black_24dp.svg">
            <span class="page-title">{{'sideNavigation.titleWorkers' | translate}}</span>
        </h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div *ngIf="allWorkers.length > 0">
                    <div class="row">
                        <div class="col-4">
                            <div class="gray-text">{{'basic.selectState' | translate }}</div>
                            <div class="form-group">
                                <select class="form-select max-content"
                                    (change)="filterStateChanged($event.target.value)">
                                    <option [value]="undefined">{{'basic.selectState' | translate }}</option>
                                    <option *ngFor="let state of allStates" [value]="state.id">{{state.definition}}
                                    </option>
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
                <div *ngIf="workers.length > 0">
                    <div class="row">
                        <table class="mt-5 min-width">
                            <thead>
                                <tr>
                                    <th role="button" scope="col" [appSort]="workers" data-order="desc" data-name="name"
                                        class="col-3 gray-text font-weight-normal min-width">
                                        <span>{{'basic.name' | translate}}</span>
                                        <img src="assets/icons/unfold_more_gray_24dp.svg">
                                    </th>
                                    <th class="col-2 gray-text font-weight-normal min-width">{{'basic.efficiency' |
                                        translate}}</th>
                                    <th scope="col" class="col-2 gray-text font-weight-normal min-width">{{'basic.teams'
                                        | translate}} </th>
                                    <th scope="col" class="col-2 gray-text font-weight-normal min-width">
                                        {{'basic.skills' | translate}}</th>
                                    <th scope="col" class="col-3 gray-text font-weight-normal min-width">
                                        {{'basic.projects' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody class="min-width">
                                <tr *ngFor="let item of workers | searchFilter: name; let i = index"
                                    class="border-bottom border-top border-gray"
                                    [ngClass]="{'opacity-25': item.disabled == 1}">
                                    <td class="col-3">
                                        <a href='#' (click)="goToWorkerDetail($event, item.id)">
                                            <div class="bold-text black-text">{{item.name}}</div>
                                            <div *ngIf="item.hasLeadInstaller == true" class="gray-text small-text">
                                                {{'planner.workersPage.leadInstaller' | translate}}</div>
                                        </a>
                                    </td>
                                    <td class="col-2">
                                        <circle-progress [percent]="item.efficiency" [radius]="25"
                                            [outerStrokeWidth]="4" [innerStrokeWidth]="4"
                                            [outerStrokeColor]="item.colorOuter" [innerStrokeColor]="item.colorInner"
                                            [space]="-4" [animation]="true" [animationDuration]="300"
                                            [titleFontSize]="'13'" [title]="item.efficiencyValue" [showUnits]=false
                                            [titleColor]="item.colorOuter" [titleFontWeight]="'600'"> </circle-progress>
                                    </td>
                                    <td class="col-2">

                                        <div>
                                            <div class="background-show-workerteams show-workerteams"
                                                *ngIf="item.showWorkerteam">
                                                <div *ngFor="let element of item.workerteams">{{element}}</div>
                                            </div>
                                            <div *ngIf="item.workerteams.length > 0"
                                                (mouseenter)="item.showWorkerteam = true"
                                                (mouseleave)="item.showWorkerteam = false" class="black-text bold-text">
                                                {{item.workerteams.length}} {{'planner.workersPage.workerteams' |
                                                translate}}</div>
                                            <div *ngIf="item.workerteams.length === 0" class="black-text bold-text">
                                                {{item.workerteams.length}} {{'planner.workersPage.workerteams' |
                                                translate}}</div>
                                        </div>
                                    </td>
                                    <td class=" col-2">
                                        <div class="same-line">
                                            <div *ngFor="let skill of item.qualifications" class="gap-between-elements
                                            colors-skill text-align-center gray-text rounded-pill px-2">
                                                {{skill.definition}}</div>
                                        </div>
                                    </td>
                                    <td class="col-3">
                                        <div class="same-line">
                                            <div *ngFor="let project of item.projects" class="gap-between-elements
                                              colors-skill text-align-center gray-text rounded-pill px-2">{{project}}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="workers.length == 0">
                    <div *ngIf="loading">{{'basic.loading' | translate}}</div>
                    <div *ngIf="!loading">{{'workers.noWorkers' | translate}}</div>
                </div>
                <button class="btn btn-primary text-white margin-top" id="newWorker" data-bs-toggle="modal"
                    data-bs-target="#workerModal" (click)="setEventListenersForSecondModal()">+
                    {{'planner.workersPage.newWorker' | translate}}</button>
                <!-- Modal -->
                <div class="modal fade" id="workerModal" tabindex="-1" aria-labelledby="workerModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-body padding-modal">
                                <img src="assets/icons/person_black_24dp.svg" class="top-position-image">
                                <span class="title ms-2">{{'planner.workersPage.newWorker' | translate}}</span>
                                <button type="button" class="btn-close float-end" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                                <form ngNativeValidate (ngSubmit)="addNewWorker()">

                                    <div class="form-group mt-3 row">
                                        <div class="col-6">
                                            <label class="gray-text"><span class="mt-1">*</span>{{'basic.firstName' |
                                                translate}}</label><br>
                                            <input type="text" [(ngModel)]="personFirstName"
                                                [ngModelOptions]="{standalone: true}" class="form-control " required>
                                        </div>
                                        <div class="col-6">
                                            <label class="gray-text"><span class="mt-1">*</span>{{'basic.lastName' |
                                                translate}}</label><br>
                                            <input type="text" [(ngModel)]="personLastName"
                                                [ngModelOptions]="{standalone: true}" class="form-control" required>
                                        </div>

                                    </div>
                                    <div class="mt-3 row form-group">
                                        <div class="col-6">
                                            <div class="gray-text form-group">
                                                <label><span class="mt-1">*</span>{{'basic.email' |
                                                    translate}}</label><br>
                                                <input type="email" [(ngModel)]="email"
                                                    [ngModelOptions]="{standalone: true}" class="form-control" required>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label><span class="mt-1">*</span>{{'basic.username' |
                                                translate}}</label><br>
                                            <input type="text" [(ngModel)]="username"
                                                [ngModelOptions]="{standalone: true}" class="form-control" required>
                                        </div>
                                        <!-- Password Section -->
                                        <div class="col-12 mt-2"> <!-- Using full width for password section -->
                                            <div class="gray-text small-text mt-2">
                                                <span>{{'basic.password' | translate}}</span>
                                            </div>
                                            <input id="login-password" type="password" class="form-control"
                                                [(ngModel)]="password" [ngModelOptions]="{standalone: true}" required>
                                        </div>
                                    </div>

                                    <div class="mt-3 row form-group">
                                        <div class="col-6">
                                            <p class="gray-text">{{'basic.interfaceLanguage' | translate}}</p>
                                            <select class="form-select" (change)="languageChange($event.target.value)">
                                                <option *ngFor="let language of interfaceLanguages"
                                                    [value]="language.databaseLanguage">{{language.displayName}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="mt-4">
                                        <input class="form-check-input" type="checkbox" id="subcontractor"
                                            [(ngModel)]="subcontractor" [ngModelOptions]="{standalone: true}"
                                            (click)="setSubcontractorValue()">
                                        <label for="subcontractor"
                                            class="gray-text ml-1">{{'planner.workersPage.subcontractor' |
                                            translate}}</label>
                                    </div>
                                    <hr>
                                    <div>
                                        <!-- <div class="mt-3 form-group">
                                            <label class="gray-text">{{'planner.workersPage.workersEfficiency' | translate}}</label>
                                            <input type="number" min="0.5" max="2.0" step="0.01" value="1.0" placeholder="1.0" [(ngModel)]="efficiencyFactor" [ngModelOptions]="{standalone: true}" class="form-control max-content">
                                        </div> -->
                                        <div class="mt-3 ">
                                            <div class=" gray-text">{{'basic.selectSkills' | translate}}</div>
                                            <div class="border border-gray border-2  scrollbar">
                                                <div *ngIf="allStates.length > 0">
                                                    <div *ngFor="let state of allStates; let i = index">
                                                        <div class="border-top-alignment"></div>
                                                        <label class="container my-2">
                                                            <input type="checkbox" [(ngModel)]="allStates[i].checked"
                                                                [ngModelOptions]="{standalone: true}"
                                                                (click)="addSkill(i)" checked="checked">
                                                            <span class="checkmark position-checkbox-skill"></span>
                                                            <span class="ms-3 skill-text ">{{state.definition}}</span>
                                                        </label>
                                                        <div *ngIf="allStates.length < 3 && i == allStates.length - 1"
                                                            class="border-top-alignment"></div>
                                                    </div>
                                                </div>
                                                <div *ngIf="allStates.length == 0">
                                                    <div class="border-top-alignment"></div>
                                                    <div class="ms-5 gray-text">{{'workers.noSkills' | translate}}</div>
                                                    <div class="border-top-alignment"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 row">
                                            <div class="col-12 gray-text">{{'basic.selectedSkills' | translate}}</div>
                                            <div class="colors-skill text-align-center gray-text max-content rounded-pill ms-2 px-2"
                                                *ngFor="let item of skillsToAdd">
                                                {{item.definition}}
                                            </div>
                                            <div *ngIf="skillsToAdd.length === 0">{{'workers.noSkillsSelected' |
                                                translate}}</div>
                                        </div>
                                    </div>

                                    <div class="mt-5">
                                        <button type="submit" id="modalSaveButton"
                                            class="btn btn-primary col-3">{{'basic.save' | translate}}</button>
                                        <button type="button" id="modalClose" class="btn btn-secondary ms-3 col-3"
                                            data-bs-dismiss="modal">{{'basic.cancel' | translate}}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>