<nav id="sidebar" class="active">
  <div class="sidebar-wrapper active sidenavstyle">
    <div class="sidebar-menu">
      <ul class="menu">
        <!-- <li class="sidebar-title">Menu</li> -->
        <!-- Link to Project Dashboard -->
        <li routerLinkActive="active" class="sidebar-item">
          <a id="nav-dashboard" routerLink="dashboard" class='sidebar-link'>
            <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
            <span>{{'sideNavigation.titleDashboard' | translate }}</span>
          </a>
        </li>

        <!-- Link to Schedule -->
        <!-- Disabled temporary, needs fixing. Does not work well with inverter + string/frame project. Only supports one of kind project.
        <li routerLinkActive="active" class="sidebar-item">
          <a id="project-schedule" routerLink="schedule" class='sidebar-link'>
            <img class="nav-icon" src="assets/icons/calendar_available_black_24dp.svg">
            <span>{{'basic.schedule' | translate }}</span>
          </a>
        </li>
      -->

        <!-- Link to Settings -->
        <li routerLinkActive="active" class="sidebar-item">
          <a id="project-settings" routerLink="project" class='sidebar-link'>
            <img class="nav-icon" src="assets/icons/settings-wheel.svg">
            <span>{{'basic.settings' | translate }}</span>
          </a>
        </li>

        <!-- Link to Weekly Planning -->
        <li routerLinkActive="active" class="sidebar-item">
          <a id="weekly-planning" routerLink="weekly-planning" class='sidebar-link'>
            <img class="nav-icon" src="assets/icons/calendar_available_black_24dp.svg">
            <span>{{'sideNavigation.titleWeeklyPlanning' | translate }}</span>
          </a>
        </li>
        <!-- <li class="sidebar-title">Menu</li> -->
        <!-- Link to Workers -->
        <li routerLinkActive="active" class="sidebar-item">
          <a routerLink="workers" class='sidebar-link'>
            <img class="nav-icon" src="assets/icons/person_black_24dp.svg">
            <span>{{'sideNavigation.titleWorkers' | translate }}</span>
          </a>
        </li>

        <!-- Link to Teams -->
        <li routerLinkActive="active" class="sidebar-item">
          <a routerLink="teams" class='sidebar-link'>
            <img class="nav-icon" src="assets/icons/groups_black_24dp.svg">
            <span>{{'basic.teams' | translate }}</span>
          </a>
        </li>
        <!-- <li class="sidebar-title">Menu</li> -->
      </ul>
    </div>
    <button class="sidebar-toggler btn x"><i data-feather="x"></i></button>
  </div>
</nav>
