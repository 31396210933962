<div class="page-container">
    <div class="page-heading">
        <h3 class="bold-text black-text"><img src="assets/icons/person_black_24dp.svg"><span class="ms-2">{{'sideNavigation.titlePlanners' | translate}}</span></h3>
    </div>
    <div class="page-content">
        <div class="card">
            <div class="card-body">
                <div class="margin-left">
                    <button class="btn btn-secondary border-zero padding-fix" (click)="toPlannersPage()">{{'basic.back' | translate}}</button>
                    <form ngNativeValidate (ngSubmit)="saveEdit(1)">
                    <div class="row margin-top">

                        <div class="col-5">
                            <div class="large bold-text row">
                                <div>
                                    <span id="planner-name">{{name}}</span>
                                    <button class="btn btn-secondary float-end me-3" (click)="toggleEdit(1)" *ngIf="!editMode1"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                </div>

                            </div>
                            <div class="row small-text mt-5 mb-3">
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.email' | translate}}</div>
                                    <div *ngIf="editMode1 == false">
                                        <div class="bold-text">{{email}}</div>
                                        <div class="bold-text" *ngIf="email == null">{{'basic.noEmail' | translate}}</div>
                                    </div>
                                    <div class="form-group" *ngIf="editMode1 == true">
                                        <input type="email" id="emailId" class="form-control max-content" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" required>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="gray-text">{{'basic.interfaceLanguage' | translate}}</div>
                                    <div *ngIf="editMode1 === false" class="bold-text">
                                        <div>{{plannerLanguage.displayName}}</div>
                                        <div *ngIf="!plannerLanguage.displayName">{{'basic.noLanguage' | translate}}</div>
                                    </div>
                                    <div [hidden]="!editMode1">
                                        <select class="form-select" (change)="languageChange($event.target.value)">
                                            <option *ngIf="!plannerLanguage.displayName" disabled [selected]="!plannerLanguage.displayName">{{'basic.noLanguage' | translate}}</option>
                                            <option *ngFor="let language of interfaceLanguages" [selected]="plannerLanguage.databaseLanguage === language.databaseLanguage" [value]="language.databaseLanguage">{{language.displayName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="editMode1">
                                <div class="col-12 mb-2">
                                    <button type="submit" class="btn btn-primary">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(1)">{{'basic.cancel' | translate}}</button>
                                </div>
                            </div>
                            <div class="border-bottom-96 mt-5"></div>
                        </div>
                        <div class="col-7 small-text border-start border-gray">
                            <div class="gray-text">
                                <span>{{'basic.projects' | translate}}</span>
                                <button class="btn btn-secondary float-end" type="button" (click)="toggleEdit(2)" *ngIf="!editMode2"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                            </div>
                            <div *ngIf="projects.length === 0">{{'planner.plannerDetail.noProjects' | translate}}</div>
                            <div *ngIf="projects.length != 0">
                                <div *ngFor="let project of projects; let i = index" class="bold-text">
                                    {{project.id}}: {{project.name}}
                                </div>

                            </div>
                            <div *ngIf="editMode2 == true" class="margin-top-1">
                                <div class=" gray-text">{{'planner.plannerDetail.selectProjects' | translate}}</div>
                                <div class="mt-3 form-group row">
                                    <div class="col-6">
                                        <p class="gray-text">{{'basic.projects' | translate}}</p>
                                        <select class="form-select" (change)="selectProject($event.target.value)">
                                            <option [value]="">{{'planners.selectProjects' | translate}}</option>
                                            <option *ngFor="let project of unselectedProjects" [value]="project.id">{{project.name}} {{project.id == currentProjectId ? ('planners.currentProject' | translate) : '' }}</option>
                                        </select>

                                        <div class="same-line mt-3">
                                            <div  *ngFor="let project of selectedProjects" (click)="unselectProject(project.id)" style="cursor: pointer;" class="gap-between-elements text-align-center gray-text rounded-pill px-2">{{project.name}}</div>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" id="saveButton" (click)="saveEdit(2)" class="btn btn-primary col-3">{{'basic.save' | translate}}</button>
                                        <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(2)">{{'basic.cancel' | translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                    <div class="row">
                        <div class="col-5 mt-4">
                            <div>
                                <div class="margin-left-fix">
                                    <img [src]="personAccount" class="image-position"><span class="ms-2 fs-6">{{'basic.account' | translate}}</span>

                                    <button class="btn btn-secondary float-end me-3 font-size-small" (click)="toggleEdit(3)" *ngIf="!editMode3 && !createUser && !accountDisabled"><img src="assets/icons/edit_gray_24dp.svg" class="image-edit"><span class="ms-1">{{'basic.edit' | translate}}</span></button>
                                    <button class="float-end btn btn-secondary font-size-small me-1" (click)="disableAccount()" *ngIf="!createUser && !accountDisabled">
                                        <img class="disable-icon" src="assets/icons/person_off_gray_48.svg">
                                        <span class="ms-1">{{'basic.disableAccount' | translate}}</span>
                                    </button>
                                    <button class="float-end btn btn-primary font-size-small me-1" (click)="activateAccount()" *ngIf="!createUserActive && accountDisabled">
                                        <img class="disable-icon" src="assets/icons/person_white_24dp.svg">
                                        <span class="ms-1">{{'basic.activateAccount' | translate}}</span>
                                    </button>
                                    <div class="gray-text font-size-small" *ngIf="accountDisabled && !createUserActive">({{'basic.disabled' | translate}})</div>
                                </div>

                            </div>
                            <div *ngIf="createUser">
                                <div class="gray-text mt-3 small-text">{{'planner.plannerDetail.noAccount' | translate}}</div>
                                <button class="btn btn-primary mt-3" (click)="createAccount()"><span class=" position-plus">+</span><span class="small-text ms-1">{{'basic.createAccount' | translate}}</span></button>
                            </div>
                            <div *ngIf="!createUser">
                                <div *ngIf="showUsername" class="gray-text small-text mt-3 ">
                                    <span>{{'basic.username' | translate}}: </span>
                                    <span class="black-text bold-text">{{username}}</span>
                                    <button *ngIf="editMode3" class="btn btn-sm btn-secondary ms-1" (click)="changeUsernameToggle()">{{'basic.change' | translate}}</button>
                                </div>
                                <form ngNativeValidate (ngSubmit)="saveEdit(3)">
                                <div *ngIf="!showUsername && editMode3">
                                    <div class="bold-text mt-2">
                                        <span *ngIf="!createUserActive">{{'basic.changeUsername' | translate}}</span>
                                        <span *ngIf="createUserActive">{{'basic.createAccount' | translate}}</span>
                                    </div>
                                    <div *ngIf="!createUserActive">
                                        <div class="small-text gray-text mt-2">{{'basic.oldUsername' | translate}}</div>
                                        <div class="black-text bold-text small-text">{{username}}</div>
                                    </div>
                                    <div class="small-text gray-text mt-2">{{'basic.newUsername' | translate}}</div>
                                    <input type="text" class="form-control max-content" [required]="!showUsername" [(ngModel)]="newUsername" [ngModelOptions]="{standalone: true}">
                                </div>
                                <div *ngIf="showPassword" class="gray-text small-text mt-2">
                                    <span>Password: </span>
                                    <span class="black-text bold-text ms-2">********</span>
                                    <button *ngIf="editMode3" class="btn btn-sm btn-secondary ms-1" (click)="changePasswordToggle()">Change</button></div>
                                <div *ngIf="!showPassword && editMode3">
                                    <div class="small-text gray-text mt-2">
                                        <span>{{'basic.password' | translate}}</span>
                                        <a class="ms-2 small-text generate-link" (click)="generatePassword()">{{'basic.generatePassword' | translate}}</a>
                                    </div>
                                    <div class="password d-flex">
                                        <input id="login-password" type="password" class="form-control password-length max-content" [required]="!showPassword" [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}">
                                        <button (click)="hideShowPassword()" class="border border-gray" type="button"><img *ngIf="!showGeneratedPassword" src="assets/icons/visibility_gray_24dp.svg"> <img *ngIf="showGeneratedPassword" src="assets/icons/visibility_off_gray_24dp.svg"></button>
                                    </div>
                                </div>
                                <div class="mb-2 mt-3" *ngIf="editMode3 == true">
                                    <button type="submit" class="btn btn-primary">{{'basic.save' | translate}}</button>
                                    <button type="button" class="btn btn-secondary ms-2" (click)="cancelEdit(3)">{{'basic.cancel' | translate}}</button>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
