import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, pipe, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, finalize, map, takeUntil } from 'rxjs/operators';
import { baseUrl } from '@environments/environment';
import { WorkOrder } from '@shared/models/work-order';
import { OrderGroup } from '@shared/models/order-group';
import { ToastService } from '@shared/services/toast/toast.service';
import { MSA } from '@shared/models/MSA';
import { TranslateService } from '@ngx-translate/core';
import { Inverter, RowNumber } from '@modules/planner/components/weekly-planning-map/weekly-planning-map.component';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService implements OnDestroy {

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translateService: TranslateService
    ) {
    this.getEndUserContacts = this.getEndUserContacts.bind(this)
   }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }

  updateWorkorderMsa(workorderId, msaId) {
    let newdataJSON = {
      "msa_id": msaId.toString()
    }

    let newdata = JSON.stringify(newdataJSON)


    this.http.patch(`${baseUrl}/api/workorders/${workorderId}`, {
      newdata
    })
    .toPromise()
    .then(response => {
      this.toastService.sendToast(true, this.translateService.instant('services.msaIDUpdated'), workorderId)
      return response
    })
    .catch(err => {
      //console.log(err);
      this.toastService.sendToast(false, err)
    }
  )


   }

  /**
   * Makes a http get request to backend and returns one work order by id
   * @param {number} id Takes id and requests work order by it
   * @returns one work order
   * @author Jesse Lindholm
   * @editedDate 29.6.2021
   *
   */
  getWorkOrder(id: number, state?: number): Observable<WorkOrder> {
    const url = `${baseUrl}/api/workorders/${id}`;
    let params: HttpParams = new HttpParams();

    // Set 'state' parameter only if it is defined
    if (state !== undefined) {
        params = params.set('state', state);
    }

    // Get with options included
    return this.http.get<WorkOrder>(url, { params: params })
        .pipe(
            map((data: WorkOrder) => {
                return data['message'];
            }),
            catchError(this.handleError<WorkOrder>(this.translateService.instant('services.getWorkorderFailed') + id))
        );
}


  /**
   * Makes a http get request to backend and returns list of work orders
   * @returns list of work orders
   * @author Jesse Lindholm
   * @editedDate 29.6.2021
   *
   * @edit 13.12.2022
   * Added parameter options for getting ALL workorders, not just 50
   * @param options gets value NULL or ALL at the moment
   *
   */
  getWorkOrders(options: string | null): Observable<WorkOrder[]> {
    const url = `${baseUrl}/api/workorders`
    if (options !== 'ALL' && options) {
      let params: HttpParams = new HttpParams()
      .set('limit', options)
      // Get with options included
      return this.http.get<WorkOrder[]>(url, {params: params})
      .pipe(
        map((data: WorkOrder[]) => {
          return data['message'];
        }),
        catchError(this.handleError<WorkOrder[]>(this.translateService.instant('services.gettingWorkorderFailed'), []))
      );
    } else {
      return this.http.get<WorkOrder[]>(`${baseUrl}/api/workorders`)
      .pipe(
        map((data: WorkOrder[]) => {
          return data['message'];
        }),
        catchError(this.handleError<WorkOrder[]>(this.translateService.instant('services.gettingWorkorderFailed'), []))
      );
    }

  }

  /**
   * Makes a http get request to backend and returns list of work orders
   * @returns list of work orders
   * @author Jesse Lindholm
   * @editedDate 29.6.2021
   *
   * @edit 13.12.2022
   * Added parameter options for getting ALL workorders, not just 50
   * @param options gets value NULL or ALL at the moment
   *
   */
  getWorkOrdersByMsa(msaId) {
      const url = `${baseUrl}/api/workordersbymsa/${msaId}`;
      return this.http.get(url)
          .pipe(
              map((data) => {
                  return data['message']
              }),
              catchError(this.handleError<any>(this.translateService.instant('services.gettingWorkorderFailed')))
          );
  }

  getRowNumbers(projectId: number): Observable<RowNumber[]> {
    const url = `${baseUrl}/api/rownumbers?project_id=${projectId}`;
    return this.http.get(url)
      .pipe(
        map((data) => {
          return data['message'] as RowNumber[];
        }),
        catchError(this.handleError<any>(this.translateService.instant('services.gettingRowNumbersFailed')))
      );
  }

  getInverters(projectId: number): Observable<Inverter[]> {
    const url = `${baseUrl}/api/inverters?project_id=${projectId}`;
    return this.http.get(url)
      .pipe(
        map((data) => {
          return data['message'] as Inverter[];
        }),
        catchError(this.handleError<any>(this.translateService.instant('services.gettingInvertersFailed')))
      );
  }

  getInverter(inverterId: number): Observable<Inverter> {
    const url = `${baseUrl}/api/inverterbyid/${inverterId}` ;
    return this.http.get(url)
      .pipe(
        map((data) => {
          return data['message'] as Inverter;
        }),
        catchError(this.handleError<Inverter>('Failed to get inverter with id: ' + inverterId))
      );
  }


  /**
   * Makes a http get request to backend and returns list of work orders
   * @returns list of work orders
   * @author Mikko
   * @editedDate 31.1.2023
   *
   *
   */
  getWorkOrdersWithoutCoordinates(): Observable<any> { // WorkOrder[]
      return this.http.get<any>(`${baseUrl}/api/workorders?returnCoordinateless=1`)
      .pipe(
        map((data: any) => {
          return data['message'];
        }),
        catchError(this.handleError<any>('Nope' ))
      );

  }

  /**
   * An http request to get a list of order groups
   * @returns A list of order groups
   */
  getOrderGroups(): Observable<OrderGroup[]> {
    return this.http.get<OrderGroup[]>(`${baseUrl}/api/ordergroups`)
      .pipe(
        map((data: OrderGroup[]) => {
          return data['message'];
        }),
        catchError(this.handleError<OrderGroup[]>(this.translateService.instant('services.getOrdergroupsFailed'), []))
      );
  }

  getOrderGroup(id: number): Observable<OrderGroup> {
    const url = `${baseUrl}/api/ordergroups/${id}`;
    return this.http.get<OrderGroup>(url)
      .pipe(
        map((data: OrderGroup) => {
          return data['message'];
        }),
        catchError(this.handleError<OrderGroup>(this.translateService.instant('services.getOrdergroupFailed') +id))
     );
   }

/**
 * This method handles making requests to update an orders position.
 * @param id determines which order we are going to update.
 * @param itemToUpdate includes a new latitude and longitude for our order.
 * With these params we can make a request to change the items location.
 * We send a newdata object to the backend which includes all the 'new data' for that specific entry.
 */
  updateWorkOrderPosition(id, itemToUpdate): any {

    let newdataJSON = {
      "coordinates": '{"lat": '+itemToUpdate.lat+',"lon": '+itemToUpdate.lng+'}'
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workorders/${id}`, {
        newdata
      })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.workorderPositionFailed'), id)
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      }
    )
  }

/**
 * This method handles making requests to update an ordergroup position.
 * @param id determines which ordergroup we are going to update.
 * @param itemToUpdate includes a new latitude and longitude for our ordergroup.
 * With these params we can make a request to change the items location.
 * We send a newdata object to the backend which includes all the 'new data' for that specific entry.
 */
  updateOrderGroupPosition(id, itemToUpdate): any {

    let newdataJSON = {
      "coordinates": '{"lat": '+itemToUpdate.lat+',"lon": '+itemToUpdate.lng+'}'
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/ordergroups/${id}`, {
        newdata
    },{observe: 'response'})
    .toPromise()
    .then(response => {
      this.toastService.sendToast(true, this.translateService.instant('services.ordergroupPositionSuccess'), id)
      return response
    })
    .catch(err => {
      //console.log(err);
      this.toastService.sendToast(false, err)
    });

  }

 /**
  * This method updates some data fields in a workorder
  * @param id
  * @param address
  * @param notes
  */
  updateWorkOrderData(id, address, notes, locationNumber): any {

    let newdataJSON = {
      "address": address,
      "notes": notes,
      "location_number": locationNumber
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workorders/${id}`, {
        newdata
      })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.workorderInfoUpdated'), id)
        return response
      })
      .catch(err => {
        //console.log(err);
        this.toastService.sendToast(false, err)
      }
    )
  }

 /**
  * This method updates some data fields in a workorder
  * @param id
  * @param address
  * @param notes
  */
  updateOrderEstimate(id, estimateArray): any {

    let jsonString = JSON.stringify(estimateArray)
    //console.log('estimate array in service')

    let newdataJSON = {
      "time_parameters": jsonString
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workorders/${id}`, {
        newdata
      })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.timeParametersUpdated'), id)
        return response
      })
      .catch(err => {
        this.toastService.sendToast(false, err)
      }
    )
  }

  /**
   * This method handles making requests that are related to order groupping/ungroupping.
   * We expect 3 values when this method is called, order id, ordergroup id and the information if the item is in a group or not, so we know if we need to group or ungroup it.
   * @param orderId is to pick the right order to change.
   * @param groupId so we know which group we going to put the order.
   * @param inGroupBeforeChange boolean so that we can separate logic if we are groupping or ungroupping.
   * We send a newdata object to the backend  which includes all the 'new data' for that specific entry.
   */
  updateOrdergroupInOrder(orderId, groupId, inGroupBeforeChange) {

    //console.log(orderId, groupId, inGroupBeforeChange)
    let newdataJSON
    if (inGroupBeforeChange) {
      newdataJSON = {
        "ordergroup_id": null
      }
    } else {
      newdataJSON = {
        "ordergroup_id": groupId
      }
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/workorders/${orderId}`, {
      newdata
    })
    .toPromise()
    .then(response => {
      if (inGroupBeforeChange) this.toastService.sendToast(true, this.translateService.instant('services.removedFromGroup'), orderId)
      if (!inGroupBeforeChange) this.toastService.sendToast(true, this.translateService.instant('services.addedToGroup'), orderId)
      return response
    })
    .catch(err => {
      //console.log(err);
      this.toastService.sendToast(false, err)
    });

  }

  createMsaArea(points, color, name, dateStart, dateEnd) {

    let data = {
      "coordinates": points.toString(),
      "name": name,
      "color": color,
      "schedule_start": dateStart,
      "schedule_end": dateEnd
    }

    return this.http.post(`${baseUrl}/api/msas`, data)
    .pipe(
      map((data) => {
        this.toastService.sendToast(true, this.translateService.instant('services.createdMSA'))
        return data['message']
      }),
      catchError(this.handleError(this.translateService.instant('services.createdMSAFailed')))
   );
  }

  /**
   * This method updates a specific enduser
   * It takes 3 params, the enduser id, name and phone. These are the only ones we update via workorder.
   * @param id
   * @param name
   * @param phone
   */
  updateEnduserContacts(id, firstname, lastname , phone, address): any {

      let newdataJSON = {
        "phone": phone,
        "contact_person_first": firstname,
        "contact_person_last": lastname,
        "address": address
      }

      let newdata = JSON.stringify(newdataJSON)

      this.http.patch(`${baseUrl}/api/endusercommunications/${id}`, {
        newdata
      })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.updatedEnduserData'))
        return response
      })
      .catch(err => {
        this.toastService.sendToast(false, err)
      });
  }

  addCallToEndUser(id, phone_communicated): Observable<any> {
    let phone_communicated_num = 0;
    if (phone_communicated) {
      phone_communicated_num = 1;
    }
    const newdataJSON = {
      "phone_communicated_num": phone_communicated_num
    };

    return this.http.patch(`${baseUrl}/api/endusercommunications/updatephonecommunicated/${id}`, newdataJSON);
}

  /**
   * We get a specific enduser with id param
   * @param id
   * @returns
   */
  getEndUserContacts(id: number): any {
    return this.http.get(`${baseUrl}/api/endusercommunications/${id}`)
    .pipe(
      map((data) => {
        return data;
      }),
      catchError(
        this.handleError(this.translateService.instant('services.getEnduserContactFailed'), [])
      )
    )
   }

   getMsas(): Observable<MSA[]> {
     return this.http.get<MSA[]>(`${baseUrl}/api/msas`)
     .pipe(
       map((data: MSA[]) => {
         return data['message']
       }),
       catchError(
         this.handleError<MSA[]>(this.translateService.instant('services.getMSAsFailed'))
       )
     )
   }

   getMsa(id: number): Observable<MSA> {
    const url = `${baseUrl}/api/msas/${id}`;
    return this.http.get<MSA>(url)
    .pipe(
      map((data: MSA) => {
        return data['message']
      }),
      catchError(this.handleError<MSA>(this.translateService.instant('services.getMSAFailed') + id ))
     );
   }

   removeMsa(id: number) {

     this.http.delete(`${baseUrl}/api/msas/${id}`)
     .pipe(takeUntil(this.componentDestroyed$))
     .subscribe({
      next: () => this.toastService.sendToast(true, this.translateService.instant('services.MSAAreaDeleted')),
      error: err => this.toastService.sendToast(false, err)
     }
    )
   }

   /**
    * Get projects default time parameters and use rural as time calculator for MSA-area
    * @param markers list of markers and we use first one them to determine correct project
    * @returns total minutes for MSA-area to be displayed in interface as installation time
    */
   calculateTimeWindows(markers) {
     let projectId;
     let subject = new Subject<number>();

     if (markers.length > 0) {
      this.http.get(`${baseUrl}/api/ordergroups/${markers[0].feature.properties.id}`)
        .pipe(
          finalize(() => {
            this.http.get(`${baseUrl}/api/projects/${projectId}`)
              .pipe(takeUntil(this.componentDestroyed$))
              .subscribe(
                projectData => {
                  (projectData['message'].default_time_parameters);
                  let totalMinutes = this.calculateTotalHours(projectData['message'].default_time_parameters, markers.length);
                  subject.next(totalMinutes);
                  return subject.asObservable();
                }
              )
          }),
          takeUntil(this.componentDestroyed$)
        )
        .subscribe(
          orderGroupsData => {
            projectId = orderGroupsData['message'].project_id;
          }
        )
     }

    return subject.asObservable();
  }

  updateMsaArea(points, msaId) {

      let newdataJSON = {
        "coordinates": points.toString(),
      }

      let newdata = JSON.stringify(newdataJSON)

      this.http.patch(`${baseUrl}/api/msas/${msaId}`, {
        newdata
      })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: () => this.toastService.sendToast(true, this.translateService.instant('services.MSAAreaCoordinatesUpdated')),
        error: err => this.toastService.sendToast(false, err)
      })
  }

  updateMsaTimes(estimates, msaId) {

      let newdataJSON = {
        "default_time_parameters": estimates,
      }

      let newdata = JSON.stringify(newdataJSON)

      return this.http.patch(`${baseUrl}/api/msas/${msaId}`, {
        newdata
      })
  }

  updateTransformerMsaId(markerId, msaId): any {
    let newdataJSON = {
      "msa_id": msaId
    }

    let newdata = JSON.stringify(newdataJSON)
    this.http.patch(`${baseUrl}/api/ordergroups/${markerId}`, {
      newdata
    }).pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: () => this.toastService.sendToast(true, this.translateService.instant('services.markerMSAIDUpdated')),
      error: err => this.toastService.sendToast(false, err['message'])
    })
  }


  /**
   * Handles Http operations that failed.
   * Lets the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      //console.log(error)
      //console.log(`${operation} failed: ${error.message}`);

      this.toastService.sendToast(false, error.message)


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  calculateTotalHours(timeParameters, markersTotal) {
     let json = JSON.parse(timeParameters)
     let totalMinutes = 0;
    for (let i = 0; i < markersTotal; i++) {
      totalMinutes = totalMinutes + (json[1].estimates[0].rural)
    }
    return totalMinutes;
  }

  createEnduser(persontype, firstname, lastname, phoneNumber, address, meterId) {
    let nameOfPerson;
    if (persontype == 0) nameOfPerson = 'Owner'
    else nameOfPerson = 'Resident'
    let newdataJSON = {
      "workorder_id": meterId.toString(),
      "contact_person_first": firstname,
      "contact_person_last": lastname,
      "person_type": persontype.toString(),
      "phone": phoneNumber,
      "address": address
    }
    return this.http.post(`${baseUrl}/api/endusercommunications`, newdataJSON)
    .pipe(
      map((data) => {
        this.toastService.sendToast(true, this.translateService.instant('services.created') + nameOfPerson)
        return data['message']
      }),
      catchError(this.handleError(this.translateService.instant('services.enduserCreationFailed')))
   );


  }

  getCallingList() {
    return this.http.get(`${baseUrl}/api/callingList`, {})
    .pipe(
      map((data) => {
        return data['message']
      }),
      catchError(this.handleError("Getting call list failed"))
   );
  }

  getWorkordersTime(workorders) : Observable<any> {

    let queryParams = new HttpParams()

    queryParams = queryParams.append("workorders", workorders) //VERY IMPORTANT
    return this.http.get(`${baseUrl}/api/workorderstime`, {params: queryParams})
    .pipe(
      map((data) => {
        return data['message']
      }),
      catchError(
        this.handleError(this.translateService.instant('services.workorderTime'), [])
      )
    )
  }

  /**
   * Calculates minutes/hours from minutes.
   * @param time
   */
  minutesToHoursAndMinutes(time) {
    let num = time;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + " h " + rminutes + " min"
  }

  updateMsaDates(dateStart, dateEnd, msaId) {
    if (dateStart == "") dateStart = null
    if (dateEnd == "") dateEnd = null
    let newdataJSON = {
      "schedule_start": dateStart,
      "schedule_end": dateEnd
    }

    let newdata = JSON.stringify(newdataJSON)

    this.http.patch(`${baseUrl}/api/msas/${msaId}`, {
      newdata
    }).pipe(takeUntil(this.componentDestroyed$))
    .subscribe({
      next: () => this.toastService.sendToast(true, this.translateService.instant('services.MSAAreaDatesUpdated')),
      error: err => this.toastService.sendToast(false, err)
    })
  }


  getWorkorderCount(projectId: number) {
    return this.http.get(`${baseUrl}/api/projects/workordercount/${projectId}`)
      .pipe(
        map((data: any) => {
          return data['message']
        }),
        catchError(this.handleError(this.translateService.instant('services.getWorkorderCount') + projectId))
     )
  }

  /**
   * Get workorders images that has been uploaded in reporting phase.
   * @param workorderId workorder id to use to fetch correct images
   * @returns set of images
   */
  getWorkorderImages(workorderId) {
    return this.http.get(`${baseUrl}/api/workorderimages/${workorderId}`)
      .pipe(
        map((data: any) => {
          return data['message']
        }),
        catchError(this.handleError(this.translateService.instant('services.getWorkorderImages') + workorderId))
      )
  }

  getWorkorderLogs(workorderId) {
    return this.http.get(`${baseUrl}/api/logs/${workorderId}`)
      .pipe(
        map((data: any) => {
          return data['message']
        }),
        catchError(this.handleError(this.translateService.instant('services.getWorkorderLogs') + workorderId))
      )
  }

  /**
   * 1.11.2022
   * Get workorder data with extra information.
   * Extra work type, fuse type are extra information.
   * Catch error.
   * @param id workorder ID
   * @returns one workorder data
   * @author Jesse Lindholm
   */
  getWorkorderWithOptions(id: number): Observable<WorkOrder> {
    const url = `${baseUrl}/api/workorders/${id}?getoptions=true`
    return this.http.get<WorkOrder>(url)
      .pipe(
        map((data: WorkOrder) => {
          return data['message']
        }),
        catchError(this.handleError<WorkOrder>(this.translateService.instant('services.getWorkorderFailed') + id))
     );
   }

   /**
    * @created 31.01.2023
    * Update coordinates to workorder, used in workorders-without-coordinates component.
    * @param id workorder's ID
    * @param coordinates coordinates object, which includes lat and lon
    * @author Jesse Lindholm
    */
   updateWorkorderCoordinates(id: number, coordinates) {
    let newdataJSON = {
      "coordinates": '{"lat": ' + coordinates.lat + ',"lon": ' + coordinates.lng + '}'
    }
    let newdata = JSON.stringify(newdataJSON)
    this.http.patch(`${baseUrl}/api/workorders/${id}`, {
        newdata
      })
      .toPromise()
      .then(response => {
        this.toastService.sendToast(true, this.translateService.instant('services.coordinatesUpdateSuccess'))
        return response
      })
      .catch(err => {
        this.toastService.sendToast(false, this.translateService.instant('services.coordinatesUpdateFailed'))
      }
    )

  }

  /**
   * Generate coordinates for single or multiple workorders using Google api
   * @param meters workorders
   * @returns response
   */
  generateCoordinates(meters) {
    let meterIds = Array()
    if (meters.length > 0) {
      meters.forEach(element => {
        meterIds.push(element.id)
      });
    }
    let workorders = JSON.stringify(meterIds)
    return this.http.patch(`${baseUrl}/api/generatecoordinates`, {workorders})
    .pipe(
      map((response: any) => {
        if (response) {
          if (response['status'] === 'success') {
            this.toastService.sendToast(true, this.translateService.instant('services.coordinatesUpdateSuccess'))
          } else if (response['status'] === 'partial_success') this.toastService.sendToast(false, this.translateService.instant('services.someWorkordersUpdated'))
          else this.toastService.sendToast(false, 'services.coordinatesUpdateFailed')
        }
        return response
      }),
      catchError(this.handleError<any>(this.translateService.instant('services.coordinatesUpdateFailed')))
   );
  }

  /**
   * Update status to workorder
   * @param id workorder ID
   * @param status
   */
  updateWorkorderStatus(id: number, status: number) {
    let newdataJSON = {
      "status_id": status
    }
    let newdata = JSON.stringify(newdataJSON)
    return this.http.patch(`${baseUrl}/api/workorders/${id}`, { newdata })
    .pipe(
      map(
        data => {
          return data
        }
      ),
      catchError(this.handleError<WorkOrder>(this.translateService.instant('services.errorUpdatingStatus') + id))
    )
  }

    reserveWorkorderToWorker(id, workerID): any {
        let newdataJSON = {
            "reserver_id": workerID
        }
        let newdata = JSON.stringify(newdataJSON)

        return this.http.patch(`${baseUrl}/api/workorders/${id}`, { newdata })
            .pipe(
                map(
                    data => {
                        this.toastService.sendToast(true, this.translateService.instant('services.reservedSuccess'), id)
                        return data
                    }
                ),
                catchError(this.handleError<WorkOrder>(this.translateService.instant('services.errorUpdatingStatus') + id))
            )

    }

    cancelReservation(id): any {
        let newdataJSON = {
            "reserver_id": null
        }

        let newdata = JSON.stringify(newdataJSON)

        return this.http.patch(`${baseUrl}/api/workorders/${id}`, { newdata })
            .pipe(
                map(
                    data => {
                        this.toastService.sendToast(true, this.translateService.instant('services.canceledSuccess'), id)
                        return data
                    }
                ),
                catchError(this.handleError<WorkOrder>(this.translateService.instant('services.errorUpdatingStatus') + id))
            )
    }

    getAllSlotsForWorkOrder(id: number) {
      let newdataJSON = {
        "status_id": status
      }
      let newdata = JSON.stringify(newdataJSON)
      return this.http.patch(`${baseUrl}/api/workorders/${id}`, { newdata })
      .pipe(
        map(
          data => {
            return data
          }
        ),
        catchError(this.handleError<WorkOrder>(this.translateService.instant('services.errorUpdatingStatus') + id))
      )
    }


  fetchImage(imageId: string | number) {
    return this.http.get(`${baseUrl}/api/fetch/${imageId}`, { responseType: 'blob' })
      .pipe(
        map(blob => URL.createObjectURL(blob)),
        catchError(error => {
          if (error.status === 404) {
            return of('assets/icons/close_red_24dp.svg');
          }
          return of('assets/icons/close_red_24dp.svg');
        })
      );
  }

}
