import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'
import {finalize, Subject, takeUntil} from "rxjs";
import { UserService } from '@shared/services/user/user.service';
import {Language} from "@shared/models/languages";
import languages from "../../../../../assets/i18n/language-list.json";
import {ToastService} from "@shared/services/toast/toast.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-planner-management',
  templateUrl: './planner-management.component.html',
  styleUrls: ['./planner-management.component.scss']
})

export class PlannerManagementComponent implements OnInit {
    componentDestroyed$: Subject<boolean> = new Subject()
    interfaceLanguages: Array<Language> = []
    currentProjectId: number = 0;
    projectId: number = 0;
    projects = Array();
    planners = Array();
    all_selected_planners = Array();

    plannerLanguage;
    firstName;
    lastName;
    password;
    password2;
    username;
    email;
    selectedProjects = Array();
    unselectedProjects = Array();
    @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef> | undefined;

    searchInput = "";
    loading = true
    confirmationForResettingValues = true;
    realClose = true;

  constructor(
      private router: Router,
    private userService: UserService,
      private translateService: TranslateService,
    private toastService: ToastService,
  ) { }


  ngOnInit(): void {
      this.getPlanners(1);
      this.getProjects();

      this.translateService.get('basic.weekDays.monday').subscribe(
          () => {
              languages.languages.forEach(language => {
                  if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`)})
                  else this.interfaceLanguages.push({databaseLanguage: language, displayName: `No translation (${language})`})
              })
          }
      )
      this.userService.getUserInfo().subscribe((data) => {
          this.currentProjectId = data.current_project;
      });
  }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true)
        this.componentDestroyed$.complete()
    }

  /**
   * 12.12.2022
   * Navigate to workers or teams page
   * @param destination name of landing page
   * @author Jesse Lindholm
   */
  navigate(destination: string) {
    if (destination === 'workers' || destination === 'teams') this.router.navigate([`/planner/${destination}`])
  }

    getProjects() {
        // this.projects = [{id:0,name:'Loading'}]
        this.userService.getAllProjects()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    this.projects = data
                    this.unselectedProjects = data
                }
            )
    }

    goToPlannerDetail(event,id) {
        event.stopPropagation();
        let uri = "planner/planner-detail/" + id;
        this.router.navigate([uri])
        return false;
    }

    addNewPlanner() {
        // Disable save button briefly to prevent adding worker more than once
        let saveButton = <HTMLButtonElement>document.getElementById('modalSaveButton')
        saveButton.disabled = true

        if(this.password.length < 3 || this.password != this.password2) {
            this.toastService.sendToast(false, this.translateService.instant('basic.passwordsDontMatch'))
            return false
        }

        let response
        this.username = this.username.trim()
        this.userService.checkUsername(this.username)
            .pipe(
                finalize(() => {
                    if (response != "username_in_use") {
                        // Close modal when this is run by clicking modal close
                        this.confirmationForResettingValues = true;
                        let button = document.getElementById('modalClose')
                        button?.click();

                        if (this.plannerLanguage === "") this.plannerLanguage = 'de'
                        this.userService.createUser(
                            this.firstName,
                            this.lastName,
                            this.email,
                            this.username,
                            this.password,
                            this.plannerLanguage,
                            this.selectedProjects,
                            1
                        )
                        .pipe(takeUntil(this.componentDestroyed$))
                            .subscribe(data => {
                                if(data.status == 200) {
                                        this.planners = Array();
                                        this.getPlanners(1);
                                        saveButton.disabled = false
                                } else {
                                    saveButton.disabled = false
                                    // todo älä sulje modaalia jos tulee virhe
                                }
                            });
                    } else {
                        Swal.fire(this.translateService.instant('services.usernameInUse'))
                        saveButton.disabled = false
                    }
                })
            )
            .subscribe(
                data => response = data
            )

        return true
    }


    /**
     * Get workers from database to make a base for page
     * Go through values of data to put correct data to correct variables
     * Finally make a single new worker for every data value in database
     */
    getPlanners(allPlanners = 0) {
        this.userService.getUsers('planner', allPlanners)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    for (const id in data) {
                        let planner = {name: "", projects: Array(), id: 0, disabled:0};
                        planner.name = data[id].firstname + " " + data[id].lastname
                        planner.projects = this.setProjects(data[id].projects) // todo tarvitaanko tätä funktiota?
                        planner.id = parseInt(id)
                        planner.disabled = data[id].disabled
                        this.planners.push(planner)
                    }
                    for (let i = 0; i < data.length; i++) {
                    }
                    this.loading = false
                }
            )
    }

    /**
     * Go through projects and get the values and save the values to current worker
     * @param projectsJSON projects from database
     */
    setProjects(projectsJSON) {
        let projects = Array()
        for (let i = 0; i < Object.keys(projectsJSON).length; i++) {
            if(Object.values(projectsJSON)[i]) {
                projects.push(Object.values(projectsJSON)[i])
            }
        }
        return projects;
    }

    /**
     * Change workers language to parameters value
     * @param language chosen language to new worker
     */
    languageChange(language: string) {
        this.plannerLanguage = language
    }

    selectProject(id) {
        if(id) {
            for(let i = 0; i < this.unselectedProjects.length; i++) {
                let project = this.unselectedProjects[i];
                if (project.id == id) {
                    this.selectedProjects.push(project);
                    this.unselectedProjects.splice(i, 1);
                    break
                }
            }
        }
    }

    unselectProject(id) {
        if(id) {
            for(let i = 0; i < this.selectedProjects.length; i++) {
                let project = this.selectedProjects[i];
                if (project.id == id) {
                    this.unselectedProjects.push(project);
                    this.selectedProjects.splice(i, 1);
                    break
                }
            }
            this.unselectedProjects.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        }
    }
    onSelected(value:string): void {
        this.projectId = parseInt(value);
    }
    addProject() {
        if(this.projectId > 0) {
            if(this.all_selected_planners.length > 0) {
                for(let i = 0; i < this.all_selected_planners.length; i++) {
                    let planner = this.all_selected_planners[i]
                    this.userService.setProjectToUser(
                        planner,
                        this.projectId
                    )
                    .pipe(takeUntil(this.componentDestroyed$))
                    .subscribe({
                        next: response =>  {
                            // console.log('planner')
                            // console.log(planner)
                            // console.log('added to project id '+this.projectId)
                        },
                        error: error => {
                            // todo case 400: err_link_exists

                            // if (error['message'].includes('404 Not Found')) {
                            //     if (!Swal.isVisible())
                            //         Swal.fire(
                            //             this.translateService.instant('planner.endUserCommunications.errorMessages.404')
                            //         );
                            // } else if (error['message'].includes('400 Bad Request')) {
                            //     if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.400_1'));
                            // } else {
                            //     if (!Swal.isVisible()) Swal.fire(this.translateService.instant('planner.endUserCommunications.errorMessages.unknown'));
                            // }
                        }
                    }
                    )
                }

                // todo odota että edellä tehty on valmis

                // empty checkboxes
                // @ts-ignore
                this.checkboxes.forEach((element) => {
                    element.nativeElement.checked = false;
                });

                // todo alert success / error / partial success
                this.toastService.sendToast(true, this.translateService.instant('planner.projectAddedSuccessText'))
                // todo refresh page
                // this.getPlanners(1);

            } else {
                Swal.fire('planner.noPlannersSelected')
            }
        } else {
            Swal.fire('planner.noProjectSelected')
        }
    }

    // checkbox functionality
    onChange(id) {
        if (this.all_selected_planners.includes(id)) {
            this.all_selected_planners = this.all_selected_planners.filter((item) => item !== id);
        } else {
            this.all_selected_planners.push(id);
        }

    }
}
