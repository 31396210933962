<div class="page-container">
   <div class="page-heading">
      <h3>
         <img class="nav-icon" src="assets/icons/list_alt_black_24dp.svg">
         <span class="page-title">{{'planner.projectPage.projectSettings' | translate }}</span>
      </h3>
   </div>
   <div class="page-content">
         <div class="card">
            <div class="card-body">
               <div id="project-basic-information">
                  <div class="row">
                     <div class="col-md-12">
                        <div>
                           <div class="row">
                              <div class="col-md-8">
                                 <div class="form-group">
                                    <h4>{{'planner.projectPage.basicInfo' | translate }}</h4>
                                 </div>
                              </div>
                              <div class="col-md-4">
                                 <button class="btn btn-secondary float-end" (click)="showProjectInfo = !showProjectInfo"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'basic.edit' | translate }}</button>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-4">
                              <div class="form-group">
                                 <label>{{'basic.name' | translate }}</label>
                                 <div>{{ projectName }}</div>
                                 <load-spinner [hidden]="projectName"></load-spinner>
                              </div>
                              <div class="form-group">
                                 <label>{{'basic.installTime' | translate }}</label>
                                 <div>{{ calculatedTime }}</div>
                                 <load-spinner [hidden]="calculatedTime"></load-spinner>
                              </div>
                              
                           </div>
                           <div class="col-4">
                              <div class="form-group">
                                 <div *ngIf="projectOrderCount > 0">
                                    <label>
                                       <span *ngIf="customerId === 2">Strings</span>
                                       <span *ngIf="customerId !== 2">Frames</span>
                                    </label>
                                    <div>{{ projectOrderCount }}</div>
                                 </div>
                                 <load-spinner *ngIf="projectOrderCount === 0"></load-spinner>
                              </div>
                              <div class="form-group">
                                 <div *ngIf="!this.inverterLoader">
                                    <label>Inverters</label>
                                    <div>{{invertersCount}}</div>
                                 </div>
                                 <load-spinner *ngIf="this.inverterLoader"></load-spinner>
                              </div>
                              
                           </div>
                           <div class="form-group col-4">
                              <label>{{'basic.info' | translate }}</label>
                              <div [hidden]="showProjectInfo">{{projectInfo}}</div>
                             <textarea class="mb-3 w-100" cols="30" rows="6" [(ngModel)]="projectInfo" [hidden]="!showProjectInfo" style="resize: none;"></textarea>
                             <button type="button" class="btn btn-primary left" [hidden]="!showProjectInfo" (click)="saveInfo()" data-bs-dismiss="modal">{{'basic.save' | translate}}</button>
                             <button type="button" class="btn btn-secondary ml-1" [hidden]="!showProjectInfo" (click)="showProjectInfo = !showProjectInfo"> {{'basic.cancel' | translate }}</button>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <hr> <!-- Divider -->

               <div *ngIf="!myParam">
                  <div class="row">
                     <!-- Project default time estimates begin -->
                     <div class="col-md-6 text-capitalize pb-10">
                        <div class="row">
                           <div class="col-md-8">
                              <div class="form-group">
                                 <h4>{{'planner.projectPage.projectTimeEstimates' | translate }}</h4>
                              </div>
                           </div>
                           <div class="col-md-4" [hidden]="estimatesLoading">
                              <button [hidden]="timeEditor" [disabled]="!projectTimeEstimates" id="timeEditBtn" class="btn btn-secondary ml-10 float-end" (click)="enableTimeEditor()"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'basic.edit' | translate }}</button>
                              <div [hidden]="!timeEditor">
                                 <button type="button" class="btn btn-primary" form="time-parameters" type="submit"> {{'basic.save' | translate }}</button>
                                 <button type="button" class="btn btn-secondary ml-1" (click)="disableTimeEditor()"> {{'basic.cancel' | translate }}</button>
                             </div>
                           </div>
                        </div>
                        <!-- Display project default time estimates -->
                        <div [hidden]="timeEditor || estimatesLoading">
                           <div [hidden]="projectTimeEstimates" class="red-text">{{'planner.projectPage.noTimeEstimates' | translate}}</div>
                           <div [hidden]="!projectTimeEstimates">
                              <div class="estimate-holder mt-10">
                                 <ng-container *ngIf="sortProjectEstimates(projectTimeEstimates) as sortedEstimates">
                                    <div class="estimates row" *ngFor="let item of sortedEstimates; let i = index">
                                       <div *ngIf="i > 0 && item.type !== sortedEstimates[i - 1].type">
                                          <hr>
                                       </div>
                                       <div class="col-md-6">
                                          <div>
                                             <span>{{ item.definition }}</span>
                                             <span class="mx-1">-</span>
                                             <span *ngIf="customerId === 2 && item.type === 1" class="blue-text">String</span>
                                             <span *ngIf="customerId !== 2 && item.type === 1" class="blue-text">Frame</span>
                                             <span *ngIf="item.type === 2" class="blue-text">Inverter</span>
                                          </div>
                                       </div>
                                       <div class="col-md-3 minutes">
                                          <div *ngIf="item.state !== 1">
                                             {{ item.estimate }} <span>{{'planner.projectPage.minutes' | translate}}</span>
                                          </div>
                                       </div>
                                       <div class="col-md-3">
                                          <div>
                                             <span class="dot" [ngStyle]="{'background-color': item.color, 'border': '1px solid black'}"></span>
                                          </div>
                                       </div>
                                    </div>
                                 </ng-container>
                              </div>
                           </div>
                        </div>
                        <!-- Project default time estimates editor -->
                        <div [hidden]="!timeEditor">
                           <form ngNativeValidate id="time-parameters" class="estimate-holder" #f="ngForm" (ngSubmit)="saveChangesToEstimates(f)">
                              <div class="mt-10">
                                 <div class="row">
                                    <div class="form-group col-md-6">
                                       <label>{{'basic.options' | translate }}</label>
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label>{{'basic.estimates' | translate }}</label>
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label>{{'basic.color' | translate }}</label>
                                    </div>
                                 </div>
                                 <ng-container *ngIf="sortProjectEstimates(projectTimeEstimates) as sortedEstimates">
                                    <div class="row" *ngFor="let item of sortedEstimates; let i = index">
                                       <div *ngIf="i > 0 && item.type !== sortedEstimates[i - 1].type">
                                          <hr>
                                       </div>
                                       <div class="col-md-6">
                                          <div class="form-group">
                                             <div>
                                                <span>{{ item.definition }}</span>
                                                <span class="mx-1">-</span>
                                                <span *ngIf="customerId === 2 && item.type === 1" class="blue-text">String</span>
                                                <span *ngIf="customerId !== 2 && item.type === 1" class="blue-text">Frame</span>
                                                <span *ngIf="item.type === 2" class="blue-text">Inverter</span>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-md-3 minutes">
                                          <div class="form-group" *ngIf="item.state !== 1">
                                             <input type="number" [(ngModel)]="item.estimate" name="{{ 'estimate_'+item.id }}" class="form-control">
                                          </div>
                                       </div>
                                       <div class="col-md-3">
                                          <div class="form-group text-capitalize">
                                             <input type="color" required class="form-control" name="{{ 'color_'+item.id }}" [(ngModel)]="item.color">
                                          </div>
                                       </div>
                                    </div>
                                 </ng-container>
                              </div>
                           </form>
                        </div>
                        <div [hidden]="!estimatesLoading" class="gray-text mt-10">
                           <load-spinner></load-spinner>
                        </div>
                     </div>

                     <!-- MSA time estimates begin -->
                     <div class="col-md-6 text-capitalize right-container pb-10">
                        <div class="row">
                           <div class="col-md-8">
                              <div class="form-group">
                                 <h4>Default group sizes</h4>
                              </div>
                           </div>
                           <div class="col-md-4">
                              <button [hidden]="msaEditor" id="msaTimeEditBtn" class="btn btn-secondary ml-10 float-end" (click)="enableMsaEditor()"><img src="assets/icons/edit_gray_24dp.svg" class="btn-icon">{{'basic.edit' | translate }}</button>
                              <div [hidden]="!msaEditor">
                                 <button type="button" class="btn btn-primary" form="msa-time-parameters" type="submit">{{'basic.save' | translate }}</button>
                                 <button type="button" class="btn btn-secondary ml-1" (click)="disableTimeEditor()">{{'basic.cancel' | translate }}</button>
                             </div>
                           </div>
                        </div>
                        <!-- Display MSA time estimates  -->
                        <div [hidden]="msaEditor" *ngIf="defaultGroupSizes">
                           <div class="estimate-holder mt-10">
                              <div class="estimates row" *ngFor="let item of defaultGroupSizes">
                                 <div class="col-md-6">
                                    <div>
                                       {{ item.definition }}
                                    </div>
                                 </div>
                                 <div class="col-md-3 minutes">
                                    <div>
                                       {{ item.estimate }} {{'basic.installers' | translate }}
                                    </div>
                                 </div>
                                 <!-- <div class="col-md-3">
                                    <div>
                                       <span class="dot" [ngStyle]="{'background-color': item.color}"></span>
                                    </div>
                                 </div> -->
                              </div>
                           </div>
                        </div>
                        <!-- Edit MSA time estimates -->
                         <div [hidden]="!msaEditor" *ngIf="defaultGroupSizes">
                             <form ngNativeValidate id="msa-time-parameters" class="estimate-holder" #f="ngForm" (ngSubmit)="saveChangesToDefaultGroupSizes(f)">
                                 <div class="mt-10">
                                     <div class="row">
                                         <div class="form-group col-md-6">
                                             <label>{{'basic.options' | translate }}</label>
                                         </div>
                                         <div class="form-group col-md-6">
                                             <label>Default Group size</label>
                                         </div>
                                     </div>
                                     <div class="row" *ngFor="let item of defaultGroupSizes">
                                         <div class="col-md-6">
                                             <div class="form-group">
                                                 <input type="text" readonly [(ngModel)]="item.definition" name="{{ 'definition_'+item.id }}" class="form-control">
                                             </div>
                                         </div>
                                         <div class="col-md-6 minutes">
                                             <div class="form-group">
                                                 <input type="number" [(ngModel)]="item.estimate" min="1" placeholder="1" name="{{ 'estimate_'+item.id }}" class="form-control">
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </form>
                         </div>
                        <!-- <div [hidden]="msaEstimatesLoading" *ngIf="msaNames?.length > 0 && selectedMsa?.length < 1" class="gray-text mt-10">
                           {{'planner.projectPage.noEstimatesMsa' | translate }}
                        </div> -->
                        <!-- <div [hidden]="!msaEstimatesLoading" class="gray-text mt-10">
                           <load-spinner></load-spinner>
                        </div> -->
                        <!-- <div *ngIf="!msaEstimatesLoading && msaNames?.length < 1" class="gray-text mt-10">
                           {{'planner.projectPage.noMsa' | translate }}
                        </div> -->
                     </div>
                  </div>
                  <hr>
                </div>
            </div>
         </div>
      </div>
</div>
