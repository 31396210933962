import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkersService } from '@modules/planner/services/workers/workers.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '@shared/models/languages';
import { ToastService } from '@shared/services/toast/toast.service';
import { UserService } from '@shared/services/user/user.service';
import {finalize, Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import languages from 'assets/i18n/language-list.json'
import {TeamsService} from "@modules/planner/services/teams/teams.service";

@Component({
    selector: 'app-worker-detail',
    templateUrl: './worker-detail.component.html',
    styleUrls: ['./worker-detail.component.scss']
})
export class WorkerDetailComponent implements OnInit {

    componentDestroyed$: Subject<boolean> = new Subject()
    workerStates;
    allStates: Array<any> = Array()
    userLang:any = null;
    name;
    teams;
    efficiency;
    qualifications;
    efficiencyValue;
    colorInner;
    colorOuter;
    phone = null;

    editMode1 = false;
    editMode2 = false;
    editMode3 = false;

    dayHeight = '40px';
    newSkill = null;
    id;
    hasCheckedValue = false;
    email = null;
    userId;
    username;
    newUsername: any = null;
    newPassword = null;
    showUsername = true;
    showPassword = true;
    createUser = true;
    personAccount = 'assets/icons/person_gray_24dp.svg';
    createUserActive = true;
    isWindowTooSmallBasic: boolean = false
    isWindowTooSmallEdit: boolean = false
    skillHeight;
    showGeneratedPassword: boolean = false
    accountDisabled: boolean = false

    // Original values, if edit is cancelled these are used
    tempName;
    tempEfficiency;
    tempEfficiencyValue;
    tempColorInner;
    tempColorOuter;
    tempQualifications;
    tempPhone;
    tempEmail;

    // New interface language
    tempLanguage: Language = {databaseLanguage: "", displayName: ""}
    workerLanguage: Language = {databaseLanguage: "", displayName: ""}
    interfaceLanguages: Array<Language> = []




    constructor(
        private route: ActivatedRoute,
        private workersService: WorkersService,
        private router: Router,
        private userService: UserService,
        private toastService: ToastService,
        private translateService: TranslateService,
        private teamsService: TeamsService,
    ) { }

    // Get correct userId from url route
    ngOnInit(): void {
        this.userLang = localStorage.getItem('userlanguage');
        this.route.paramMap.subscribe(pmap =>  this.getStatuses(pmap.get('id')));
        if (window.innerWidth < 950) {
            this.isWindowTooSmallBasic = true
            this.isWindowTooSmallEdit = true
        } else if (window.innerWidth < 1455) this.isWindowTooSmallEdit = true
        this.translateService.get('basic.weekDays.monday').subscribe(
            () => this.getTranslations()
        )

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth < 950) this.isWindowTooSmallBasic = true
        else this.isWindowTooSmallBasic = false
        if (window.innerWidth < 1455) this.isWindowTooSmallEdit = true
        else this.isWindowTooSmallEdit = false
    }

    getTranslations() {
        languages.languages.forEach(language => {
            if (this.translateService.instant(`languages.${language}`) !== `languages.${language}`) this.interfaceLanguages.push({databaseLanguage: language, displayName: this.translateService.instant(`languages.${language}`)})
            else this.interfaceLanguages.push({databaseLanguage: language, displayName: `No translation (${language})`})
        })
    }

    getStatuses(workerId) {
        this.teamsService.getStatuses()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
                data => {
                    this.workerStates = data

                    for (let i = 0; i < this.workerStates.length; i++) {
                        let definition
                        let defjson = JSON.parse(this.workerStates[i].definition) || {}
                        if(this.userLang) {
                            definition = defjson[this.userLang] ||
                                defjson['en'] ||
                                undefined;
                        } else {
                            definition = defjson['en'] || undefined;
                        }
                        this.workerStates[i].definition = definition
                        if (this.workerStates[i].state !== 1) this.allStates.push({id: this.workerStates[i].id, definition: definition})
                    }
                    this.getWorker(workerId)
                }
            )
    }

    /**
     * This function is base for all data in single worker page. We get the data
     * from api call and put them to variables to be able to use them in HTML
     * @param id workers id to perfrom api call with
     */
    getWorker(id) {
        this.userId = id
        this.userService.getUserInfoWithId(id).subscribe(
            data => {
                if (data.disabled == 1) this.accountDisabled = true
                else this.accountDisabled = false
                this.username = data.username
                if (this.username != null) {
                    this.createUser = false;
                    if (data.disabled == 0) this.personAccount = 'assets/icons/person_blue_24dp.svg';
                    this.createUserActive = false;
                }
                if (data.language) {
                    for (let i = 0; i < this.interfaceLanguages.length; i++) {
                        const language = this.interfaceLanguages[i]
                        if (language.databaseLanguage === data.language) this.workerLanguage = language
                    }
                }
                this.id = id;
                this.name = data.firstname + " " + data.lastname
                // Set workerteams with workerteams data
                this.setWorkerteams(data.workerteams);
                // If efficiency value is null use this if statement and set them to 0
                // Else multiply efficiency with 100 to get prosent value of it instead of 1.02 value for example
                // Needs to multiply because of addon we use to display values in bubble form
                if (data.efficiency == null) {
                    this.efficiency = 0
                    this.efficiencyValue = 0.0
                } else {
                    this.efficiency = (data.efficiency * 100)
                    this.efficiencyValue = data.efficiency
                }
                // This if statement sets green color if efficiency is more than 1 and red if it is less.
                if (data.efficiency >= 1.0) {
                    this.colorInner = '#10A231'
                    this.colorOuter = '#05791F'
                    this.efficiency = (data.efficiency - 1) * 100
                } else {
                    this.colorOuter = '#FF0000'
                    this.colorInner = '#ffcccb'
                }
                this.setSkills(data.qualifications)
                this.email = data.email;
            }
        )
    }

    /**
     * Set teams variable from workerteams data to use them in HTML.
     * If length is 0 apply null to teams
     * @param workerteamsJSON workerteams we get from api call
     */
    setWorkerteams(workerteamsJSON) {
        let workerteams = Array()
        // Loop through JSON data. Use keys to loop them and push values to workerteams array
        for (let i = 0; i < Object.keys(workerteamsJSON).length; i++) {
            workerteams.push(Object.values(workerteamsJSON)[i])
        }
        if (workerteams.length > 0) this.teams = workerteams;
        else this.teams = null;
    }

    /**
     * Navigation back to workers page. Function is called when "back" is pressed in interface
     */
    toWorkersPage() {
        this.router.navigate(['planner/workers'])
    }

    /**
     * Loop through qualificationsFromApiCall and push them to skills then apply skills to variable qualifications
     * @param qualificationsFromApiCall data we get from api call
     */
    setSkills(qualifications) {
        let skills = Array()
        if (qualifications != null) {
            try {
                let parsedQualifications = JSON.parse(qualifications)
                skills = this.allStates.filter(skill => parsedQualifications.includes(skill.id));
                // Add checked: true to skills
                // skills = this.allStates.filter(skill => parsedQualifications.includes(skill.id))
                //     .map(skill => ({ ...skill, checked: true }));
                // Add checked to allstates
                this.allStates = this.allStates.map(state => ({
                    ...state,
                    checked: !!skills.find(skill => skill.id === state.id)
                }));
            } catch (e) {
                // console.log(e)
            }
        }
        // Set local variable skills to current worker
        this.qualifications = skills;

        // Set checked value to true if length is more than 0
        if (this.allStates.length < 1) this.skillHeight = '80px';
        if (this.allStates.length < 2) this.skillHeight = '100px';
        if (this.allStates.length >= 2) this.skillHeight = '150px';
    }

    /**
     * Function is called when user presses edit button in interface
     */
    toggleEdit(tab) {
        if (tab == 1) {
            this.editMode1 = true;
            this.tempPhone = this.phone;
            this.tempEmail = this.email;
            // New variables to temp
            this.tempLanguage = this.workerLanguage
        }

        // Css logic
        if (tab == 2) {
            this.editMode2 = true;
            this.dayHeight = '50px'
            // Set temp values if user clicks cancel in edit mode apply these values
            // to get same values that was before user clicked edit
            this.tempEfficiency = this.efficiency;
            this.tempEfficiencyValue = this.efficiencyValue;
            this.tempColorInner = this.colorInner;
            this.tempColorOuter = this.colorOuter;
            this.tempQualifications = JSON.stringify(this.qualifications);
        }

        if (tab == 3) {
            this.editMode3 = true;
        }

    }


    // Most important function for saving changes after user made them and
    // Sending them to database
    saveEdit(tab) {

        if (tab == 1) {
            let email = ''
            let language = ''
            // Check if email has been changed and fire pop up for confirmation if it has been changed.
            if (this.email != this.tempEmail) {
                email = this.email || ''
            }
            if (this.workerLanguage.databaseLanguage !== this.tempLanguage.databaseLanguage) {
                language = this.workerLanguage.databaseLanguage || ''
            }
            this.workersService.updateWorker(this.id, this.phone, email, language, null, null, null, null)
            this.editMode1 = false;
        }
        if (tab == 2) {
            let efficiencyValue = ''
            // Set colors and percent correctly for bubble
            if (this.efficiencyValue >= 1.0) {
                this.colorInner = '#10A231'
                this.colorOuter = '#05791F'
                this.efficiency = (this.efficiencyValue - 1) * 100
            } else {
                this.colorOuter = '#FF0000'
                this.colorInner = '#ffcccb'
                this.efficiency = this.efficiencyValue * 100;
            }
            efficiencyValue = this.efficiencyValue

            let checkedQualifications = '[' + this.qualifications.map(qualification => qualification.id).sort().join(', ') + ']';

            this.workersService.updateWorker(this.id, null, null, null, efficiencyValue, checkedQualifications, null, null)
            this.editMode2 = false;
        }

        if (tab == 3) {
            if (this.newUsername != null) {
                let response;
                this.workersService.checkUsername(this.newUsername)
                    .pipe(
                        finalize(() => {
                            if (response != "username_in_use") {
                                this.workersService.updateWorker(this.id, null, null, null, null, null, this.newUsername, this.newPassword)
                                this.username = this.newUsername;
                                this.createUserActive = false;
                                this.showUsername = true;
                                this.showPassword = true;
                                this.editMode3 = false;
                                this.accountDisabled = false
                            }
                            this.newUsername = null;
                            this.newPassword = null;
                        })
                    )
                    .subscribe(
                        data => response = data
                    )

            }
            if (this.newUsername == null && this.newPassword != null) {
                this.workersService.updateWorker(this.id, null, null, null, null, null, this.newUsername, this.newPassword)
                this.newUsername = null;
                this.newPassword = null;
                this.editMode3 = false;
                this.showUsername = true;
                this.showPassword = true;
                this.createUserActive = false;
            }
            if (this.showGeneratedPassword) this.showGeneratedPassword = false


            this.editMode3 = false;
        }
    }

    /**
     * Logic for cancelling edit by user. Apply old values to all places that could have been changed.
     */
    cancelEdit(tab) {
        if (tab == 1) {
            this.editMode1 = false;
            this.phone = this.tempPhone;
            this.email = this.tempEmail;
            this.workerLanguage = this.tempLanguage
        }

        if (tab == 2) {
            this.editMode2 = false;
            this.dayHeight = '30px';
            this.efficiency = this.tempEfficiency;
            this.efficiencyValue = this.tempEfficiencyValue;
            this.colorInner = this.tempColorInner;
            this.colorOuter = this.tempColorOuter;
            this.qualifications = JSON.parse(this.tempQualifications);
        }

        if (tab == 3) {
            if (this.createUserActive == true) {
                this.createUser = true
                this.personAccount = 'assets/icons/person_gray_24dp.svg';
            }
            this.editMode3 = false;
            this.showUsername = true;
            this.showPassword = true;
            this.newPassword = null;
            this.newUsername = null;
            if (this.showGeneratedPassword) this.showGeneratedPassword = false
        }


    }

    /**
     * If skill is clicked set qualifications checked to true and if it alreydy true set it to false.
     * Used in saving data to database.
     * @param index correct skill to access
     */
    skillClicked(i) {
        const skill = this.allStates[i];
        if (!skill.checked) {
            if (!this.qualifications.some(s => s.id === skill.id)) {
                this.qualifications.push(skill);
                this.allStates[i].checked = true
            }
        } else {
            this.qualifications = this.qualifications.filter(s => s.id !== skill.id);
            this.allStates[i].checked = false
        }
    }

    changeUsernameToggle() {
        this.showUsername = false;

    }

    changePasswordToggle() {
        this.showPassword = false;
    }

    generatePassword() {
        this.newPassword = uuidv4()
    }

    createAccount() {
        this.personAccount = 'assets/icons/person_blue_24dp.svg';
        this.createUser = false;
        this.editMode3 = true;
        this.showPassword = false;
        this.showUsername = false;
    }

    hideShowPassword() {
        let passwordField = document.getElementById("login-password");

        this.showGeneratedPassword = !this.showGeneratedPassword

        const type = passwordField!.getAttribute("type") === "password" ? "text" : "password";
        passwordField!.setAttribute("type", type);
    }

    disableAccount() {
        Swal.fire({
            title: this.translateService.instant('planner.workerDetail.disableAccountConfirmation'),
            showCancelButton: true,
            confirmButtonText: this.translateService.instant('basic.yes'),
        }).then((result) => {
            if (result.isConfirmed) {
                this.workersService.changeDisabledStatus(1, this.userId).subscribe()
                this.accountDisabled = true
                this.personAccount = 'assets/icons/person_gray_24dp.svg';
            }
        })
    }

    activateAccount() {
        this.workersService.changeDisabledStatus(0, this.userId).subscribe()
        this.accountDisabled = false
        this.personAccount = 'assets/icons/person_blue_24dp.svg';
    }

    languageChange(databaseLanguage) {
        for (let i = 0; i < this.interfaceLanguages.length; i++) {
            const language = this.interfaceLanguages[i];
            if (language.databaseLanguage === databaseLanguage) this.workerLanguage = language

        }
    }


}
