import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '@app/app-routing.module';
import { OverviewComponent } from '@layout/overview/overview.component';
import { SharedModule } from '@shared/shared.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverviewDashboardComponent } from './components/overview-dashboard/overview-dashboard.component';
import { OverviewSideNavComponent } from './components/overview-side-nav/overview-side-nav.component';
import { FilterStatesByTypePipe } from '@modules/planner/pipes/filter-states-by-type.pipe';
import { PlannerModule } from '@modules/planner/planner.module';

/**
 * This is the core module for overview. This binds all the logic we have for a overview type user.
 * Declares all components that are available for overview, then imports modules that have a more global job.
 */

@NgModule({
  declarations: [
    OverviewComponent,
    OverviewDashboardComponent,
    OverviewSideNavComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    AppRoutingModule,
    SharedModule,

    // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 20,
      outerStrokeWidth: 8,
      innerStrokeWidth: 2,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      showSubtitle: false
    }),
    NgbModule,
    PlannerModule
  ]
})
export class OverviewModule { }
